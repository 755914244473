import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import BillingAddInputField from "../components/BillingAddInputField";
import DatePicker from "react-datepicker";
import moment from "moment";
import PopUpModal from "../components/PopUpModal";
import { useMutation, useQuery } from "react-query";
import axios from "axios";
import Sidebar from "../components/Sidebar";

const NewRegister = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [count, setCount] = useState(0);
  const [addRegisterPopup, setRegisterPopup] = useState(null);
  const [roomStatus, setRoomStatus] = useState(null);
  let hostelName = localStorage.getItem("hostelName");
  console.log("HOSTEL NAME : ", hostelName);
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    watch,
  } = useForm();

  const getViewRoom = async () => {
    return await axios.get(
      `https://api.2kvirtualworld.com/hostelBillingmanagement-0.0.1-SNAPSHOT/rooms/getAllNewRoom/${hostelName}`
    );
  };
  const { mutate, isLoading, isError, isSuccess } = useMutation(
    (postData) =>
      axios.post(
        `https://api.2kvirtualworld.com/hostelBillingmanagement-0.0.1-SNAPSHOT/newregister/${hostelName}`,
        postData
      ),
    {
      onSuccess: () => {
        console.log("data added successfully...");
        setRegisterPopup(1);
      },
      onError: (error) => {
        // Handle error
        console.error("Mutation failed:", error);
      },
    }
  );
  const { data } = useQuery("getViewRoom", getViewRoom);
  let details = data && data?.data;

  let roomNumber = details?.map((dd, i) => {
    return dd?.roomNo;
  });
  console.log("ROOMNUMBER", roomNumber);
  useEffect(() => {
    setValue("roomNo", state?.roomNo);
  });
  const onSubmit = (data) => {
    let formData = {
      name: data?.name,
      mobileNo: data?.mobileNo,
      emailId: data?.emailId,
      roomNo: data?.roomNo,
      emergencyNo: data?.emergencyNo,
      dateOfBirth: moment(data?.dateOfBirth).format("YYYY-MM-DD"),
      dateOfJoining: moment(data?.dateOfJoining).format("YYYY-MM-DD"),
      address: data?.address,
      bedNo: Number(data?.bedNo),
      status: data?.status,
      selectHostel: " ",
      selectFoodOption: data?.selectFoodOption,
      advanceAmount: data?.advancePayment,
      rentForMonth: data?.rentForMonth,
    };
    if (Array.isArray(roomNumber) && formData?.roomNo != null) {
      const isAvailable = roomNumber?.includes(formData?.roomNo);

      if (isAvailable) {
        mutate(formData);
      } else {
        setRoomStatus(1);
      }
    }
  };

  // Function to get today's date in YYYY-MM-DD format
  const getTodayDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  return (
    <>
      <section className="registrationMainContainer">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-2 p-0 m-0">
              <Sidebar />
            </div>
            <div className="col-lg-10 px-4 py-2 m-0">
              <div className="register-heading-block">
                <div className="new-register-title-block">
                  <img
                    src="/assets/images/back-arrow.png"
                    alt="no-image"
                    className="arrow-img-icon"
                    onClick={() => {
                      navigate(-1);
                    }}
                  />
                  <h5 className="view-bill-heading --register-head pt-2">
                    NEW REGISTER
                  </h5>
                </div>
                {/* <div>
                  <Controller
                    name="role"
                    control={control}
                    defaultValue=""
                    rules={{ required: true }} // Example: Add validation rules
                    render={({ field }) => (
                      <select {...field} className="selectDropDown">
                        <option value="">SELECT A HOSTEL</option>
                        <option value="SM Homes">SM Homes</option>
                        <option value="Elite">Elite Stays</option>
                      </select>
                    )}
                  />

                  <button
                    className="view-register-btn"
                    onClick={() => {
                      navigate("/viewRegister");
                    }}
                  >
                    VIEW REGISTER
                  </button>
                </div> */}
                <div className="new-room-head">
                  {/* <Controller
                    name="selectHostel"
                    control={control}
                    defaultValue=""
                    // rules={{ required: true }}
                    render={({ field }) => (
                      <select
                        {...field}
                        className="selectDropDown --new-room-dropdown"
                      >
                        <option value="">SELECT A HOSTEL</option>
                        <option value="Hostel A">Hostel A</option>
                        <option value="Hostel B">Hostel B</option>
                        <option value="Hostel C">Hostel C</option>
                      </select>
                    )}
                  /> */}
                  <button
                    className="view-register-btn --view-register"
                    onClick={() => {
                      navigate("/viewRegister");
                    }}
                  >
                    VIEW REGISTER
                  </button>
                </div>
              </div>
              <div className="register-form-block my-1">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="new-register-input-block">
                    <div className="register-container">
                      <label className="BillingAddInputLabel --new-register-label-two">
                        NAME
                      </label>
                      <div className="newRegisterInputBlockTwo">
                        <Controller
                          control={control}
                          name="name"
                          render={(field) => (
                            <BillingAddInputField
                              {...field}
                              placeholder="Name"
                              type="text"
                              newRegisterInput="newRegisterInput"
                            />
                          )}
                          rules={{ required: true }}
                        />
                      </div>
                    </div>
                    <div className="register-container">
                      <label className="BillingAddInputLabel --new-register-label-two">
                        EMAIL ID
                      </label>
                      <div className="newRegisterInputBlockTwo">
                        <Controller
                          control={control}
                          name="emailId"
                          render={(field) => (
                            <BillingAddInputField
                              {...field}
                              placeholder="Email Id"
                              type="emailid"
                              newRegisterInput="newRegisterInput"
                            />
                          )}
                          rules={{ required: true }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="new-register-input-block">
                    <div className="register-container">
                      <label className="BillingAddInputLabel --new-register-label-two">
                        MOBILE NUMBER
                      </label>
                      <div className="newRegisterInputBlockTwo">
                        <Controller
                          control={control}
                          name="mobileNo"
                          render={(field) => (
                            <BillingAddInputField
                              {...field}
                              placeholder="Mobile Number"
                              type="tele"
                              newRegisterInput="newRegisterInput"
                            />
                          )}
                          rules={{ required: true }}
                        />
                      </div>
                    </div>
                    <div className="register-container">
                      <label className="BillingAddInputLabel --new-register-label-two">
                        EMERGENGY NUMBER
                      </label>
                      <div className="newRegisterInputBlockTwo">
                        <Controller
                          control={control}
                          name="emergencyNo"
                          render={(field) => (
                            <BillingAddInputField
                              {...field}
                              placeholder="Emergency Number"
                              type="tele"
                              newRegisterInput="newRegisterInput"
                            />
                          )}
                          rules={{ required: true }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="new-register-input-block">
                    <div className="register-container">
                      <label className="BillingAddInputLabel --new-register-label-two">
                        DATE OF BIRTH
                      </label>
                      <div className="newRegisterInputBlockTwo">
                        <Controller
                          name="dateOfBirth"
                          control={control}
                          defaultValue=""
                          render={({ field }) => (
                            <DatePicker
                              className="newRegisterInput"
                              selected={
                                field.value ? new Date(field.value) : null
                              }
                              onChange={(date) => {
                                // Format the selected date and update the field value
                                field.onChange(
                                  date ? moment(date).format("YYYY-MM-DD") : ""
                                );
                              }}
                              placeholderText="DD/MM/YYYY"
                              dateFormat="dd/MM/yyyy" // Format displayed in the date picker
                            />
                          )}
                        />
                      </div>
                    </div>
                    <div className="register-container">
                      <label className="BillingAddInputLabel --new-register-label-two">
                        DATE OF JOINING
                      </label>
                      <div className="newRegisterInputBlockTwo">
                        <Controller
                          control={control}
                          name="dateOfJoining"
                          render={({ field }) => (
                            <DatePicker
                              className="newRegisterInput"
                              onChange={(date) => field.onChange(date)}
                              selected={field.value}
                              // dateFormat="YYYY/MM/DD"
                              // dateFormat='Pp/'
                              placeholderText="DD/MM/YY"
                              minDate={new Date(getTodayDate())} // Disable previous days
                            />
                          )}
                          rules={{ required: true }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="new-register-input-block">
                    <div className="register-container">
                      <label className="BillingAddInputLabel --new-register-label-two">
                        ROOM NO
                      </label>
                      <div className="newRegisterInputBlockTwo">
                        <Controller
                          control={control}
                          name="roomNo"
                          render={(field) => (
                            <BillingAddInputField
                              {...field}
                              placeholder="Room No"
                              type="number"
                              newRegisterInput="newRegisterInput"
                            />
                          )}
                          rules={{ required: true }}
                        />
                      </div>
                    </div>
                    <div className="register-container">
                      <label className="BillingAddInputLabel --new-register-label-two">
                        BED NUMBER
                      </label>
                      <div className="newRegisterInputBlockTwo">
                        <Controller
                          control={control}
                          name="bedNo"
                          render={(field) => (
                            <BillingAddInputField
                              {...field}
                              placeholder="Bed Number"
                              type="text"
                              newRegisterInput="newRegisterInput"
                            />
                          )}
                          rules={{ required: true }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="new-register-input-block">
                    <div className="register-container">
                      <label className="BillingAddInputLabel --new-register-label-two">
                        SELECT FOOD OPTION
                      </label>
                      <div className="newRegisterInputBlockTwo">
                        <Controller
                          name="selectFoodOption"
                          control={control}
                          defaultValue=""
                          rules={{ required: true }} // Example: Add validation rules
                          render={({ field }) => (
                            <select {...field} className="newRegisterInput">
                              <option value="">Select Food</option>
                              <option value="With Food">With Food</option>
                              <option value="Without Food">Without Food</option>
                              <option value="Own Cooking">Own Cooking</option>
                            </select>
                          )}
                        />
                      </div>
                    </div>
                    <div className="register-container">
                      <label className="BillingAddInputLabel --new-register-label-two">
                        ADVANCE PAYMENT
                      </label>
                      <div className="newRegisterInputBlockTwo">
                        <Controller
                          control={control}
                          name="advancePayment"
                          render={(field) => (
                            <BillingAddInputField
                              {...field}
                              placeholder="Advance Payment"
                              type="number"
                              newRegisterInput="newRegisterInput"
                            />
                          )}
                          rules={{ required: true }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="new-register-input-block">
                    <div className="register-container">
                      <label className="BillingAddInputLabel --new-register-label-two">
                        RENT FOR MONTH
                      </label>
                      <div className="newRegisterInputBlockTwo">
                        <Controller
                          control={control}
                          name="rentForMonth"
                          render={(field) => (
                            <BillingAddInputField
                              {...field}
                              placeholder="Rent For Month"
                              type="number"
                              newRegisterInput="newRegisterInput"
                            />
                          )}
                          rules={{ required: true }}
                        />
                      </div>
                    </div>
                    <div className="register-container">
                      <label className="BillingAddInputLabel --new-register-label-two">
                        ADDRESS
                      </label>
                      <div className="newRegisterInputBlockTwo">
                        <Controller
                          control={control}
                          name="address"
                          render={(field) => (
                            <BillingAddInputField
                              {...field}
                              placeholder="Address"
                              type="text"
                              newRegisterInput="newRegisterInput"
                            />
                          )}
                          rules={{ required: true }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="new-register-input-block">
                    <div className="register-container">
                      <label className="BillingAddInputLabel --new-register-label-two">
                        STATUS
                      </label>
                      <div className="newRegisterInputBlockTwo">
                        <Controller
                          name="status"
                          control={control}
                          defaultValue=""
                          rules={{ required: true }} // Example: Add validation rules
                          render={({ field }) => (
                            <select {...field} className="newRegisterInput">
                              <option value="">status</option>
                              <option value="Active">Active</option>
                              <option value="Inactive">Inactive</option>
                            </select>
                          )}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="new-register-input-block">
                    <div className="register-btn-block ">
                      <button className="register-submit-btn">
                        {isLoading ? (
                          <i class="fa fa-spinner fa-spin"></i>
                        ) : (
                          "Submit"
                        )}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <PopUpModal isOpen={addRegisterPopup === null}>
        <div className="popup-overlay">
          <div className="confirmation-popup">
            <div className="p-0 m-0 popupBoxBack">
              <div
                style={{ width: "15px", height: "15px", cursor: "pointer" }}
                onClick={() => {
                  setRegisterPopup(null);
                }}
              >
                <img
                  src="/assets/images/close.png"
                  className="popUpBackGround"
                />
              </div>
            </div>

            <div className="d-flex flex-column justify-content-center align-items-center">
              <span className="popUpContent p-2">Thank you!</span>
              <span className="popUpContentPara pb-3">
                User added successfully..
              </span>
              <button
                onClick={() => {
                  navigate("/viewRegister");
                }}
              >
                OK
              </button>
            </div>
          </div>
        </div>
      </PopUpModal>
      <PopUpModal isOpen={roomStatus === null}>
        <div className="popup-overlay">
          <div className="confirmation-popup">
            <div className="p-0 m-0 popupBoxBack">
              <div
                style={{ width: "15px", height: "15px", cursor: "pointer" }}
                onClick={() => {
                  setRegisterPopup(null);
                }}
              >
                <img
                  src="/assets/images/close.png"
                  className="popUpBackGround"
                />
              </div>
            </div>

            <div className="d-flex flex-column justify-content-center align-items-center">
              {/* <span className="popUpContent p-2">Thank you!</span> */}
              <span className="popUpContentPara pb-3 pt-4">
                Room Not Available Please Check Available Room !!
              </span>
              <button
                onClick={() => {
                  navigate("/");
                }}
              >
                OK
              </button>
            </div>
          </div>
        </div>
      </PopUpModal>
    </>
  );
};

export default NewRegister;
