import axios from "axios";
import React, { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import PopUpModal from "../PopUpModal";
const ViewRoomForm = () => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [addSubTicketPopUp, setAddSubTicketPopUp] = useState(null);
  const [deletePopup, setDeletePopUp] = useState(null);
  const [deleteId, setDeleteId] = useState(null);
  let hostelName = localStorage.getItem("hostelName");
  // console.log("HOSTEL NAME", hostelName);
  const getViewRoom = async () => {
    return await axios.get(
      `https://api.2kvirtualworld.com/hostelBillingmanagement-0.0.1-SNAPSHOT/rooms/getAllNewRoom/${hostelName}`
    );
  };
  const { data, isLoading, isError } = useQuery("getViewRoom", getViewRoom);
  let details = data && data?.data;
  console.log("DETAILS", details);

  const filteredData =
    details &&
    details?.filter(
      (item) =>
        String(item?.roomNo)
          ?.toLowerCase()
          .startsWith(searchTerm?.toLowerCase()) ||
        String(item?.status)
          ?.toLowerCase()
          .startsWith(searchTerm?.toLowerCase())
    );

  return (
    <>
      <div className="view-room-head-block my-2">
        <div className="view-room-heading">
          <img
            src="/assets/images/back-arrow.png"
            style={{ width: "40px", height: "40px", cursor: "pointer" }}
            className="arrow-img-icon"
            alt="no-image"
            onClick={() => navigate(-1)}
          />
          <h5 className="view-bill-heading pt-2">VIEW ROOM</h5>
        </div>
        <div className="view-room-head-new-btn-block">
          <button
            className="--new-room-btn"
            onClick={() => {
              navigate("/newRoom");
            }}
          >
            NEW ROOM
          </button>
          <input
            type="search"
            className="table-search --view-room-search"
            placeholder="search"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
      </div>

      <div className="view-room-main-block">
        {filteredData?.map((d, i) => {
          return (
            <>
             
                <div
                  class="card  card-block"
                  key={i}
                  style={{ boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)" }}
                >
                  <div className="text-end">
                    <img
                      src="/assets/images/edit-icon.png"
                      className="--image-click"
                      alt="no-image"
                      onClick={() => {
                        navigate("/editRoom", { state: d });
                      }}
                    />
                    {/* <img
                        src="/assets/images/delete-icon.png"
                        className="--image-click"
                        alt="no-image"
                        onClick={() => {
                          setAddSubTicketPopUp(true);
                          setDeleteId(d?.id);
                        }}
                      /> */}
                  </div>
                  <div
                    class="card-body card-content-block"
                    onClick={() => {
                      navigate("/viewBeds", { state: d });
                    }}
                  >
                    <p className="d-flex gap-3 card-content-para">
                      <span class="card-title">Room No : {d?.roomNo}</span>
                      <span className="card-title">
                        Sharing : {d?.noOfBeds}
                      </span>
                    </p>
                    <p className="card-content-para --room-type">
                      {d?.roomType}
                    </p>
                    {d?.status == "Available" || d?.status == "available" ? (
                      <p
                        className="hostel-status"
                        style={{ color: "greenyellow" }}
                      >
                        {d?.status}
                      </p>
                    ) : (
                      <p className="hostel-status" style={{ color: "yellow" }}>
                        {d?.status}
                      </p>
                    )}
                  </div>
                </div>
              
            </>
          );
        })}
      </div>
    </>
  );
};

export default ViewRoomForm;
