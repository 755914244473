import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import BillingAddInputField from "../BillingAddInputField";
import { useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import axios from "axios";
import PopUpModal from "../PopUpModal";
const NewRoomForm = () => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    watch,
  } = useForm();
  const navigate = useNavigate();
  const [errroMessage, setErrorMessage] = useState("");
  const [showErrorPopUp, setErrorPopUp] = useState(null);
  let hostelName = localStorage.getItem("hostelName");
  console.log("hostelName", hostelName);
  const [addRoomPopup, setAddRoomPopup] = useState(null);
  const { mutate, isLoading, isError, isSuccess, data, error } = useMutation(
    (postData) =>
      axios.post(
        `https://api.2kvirtualworld.com/hostelBillingmanagement-0.0.1-SNAPSHOT/rooms/${hostelName}`,
        postData
      ),
    {
      onSuccess: (data) => {
        console.log("data", data);
        setAddRoomPopup(1);
      },
      onError: (error) => {
        if (error?.response) {
          // Server responded with a status code
          const { status, data } = error?.response;
          setErrorMessage(data);
          console.log("Error Response:", data);
          setErrorPopUp(1);
        } else {
          console.log("Unexpected Error:", error?.message);
        }
      },
    }
  );
  const onSubmit = (data) => {
    let formData = {
      roomNo: data?.roomNumber,
      noOfBeds: data?.NoOfBeds,
      roomType: data?.roomType,
      ebNumber: data?.ebNo,
      selectHostel: "Hostel A",
      foodOption: "with food"
    };
    if (formData) {
      mutate(formData);
      console.log("data", formData);
    }
  };

  return (
    <>
      <div className="register-heading-block">
        <div className="new-room-heading">
          <img
            src="/assets/images/back-arrow.png"
            alt="no-image"
            className="arrow-img-icon"
            onClick={() => {
              navigate(-1);
            }}
          />
          <h5 className="view-bill-heading --register-head pt-2">NEW ROOM</h5>
        </div>
        <div className="new-room-head">
          {/* <Controller
            name="selectHostel"
            control={control}
            defaultValue=""
            // rules={{ required: true }}
            render={({ field }) => (
              <select {...field} className="selectDropDown --new-room-dropdown">
                <option value="">SELECT A HOSTEL</option>
                <option value="SM Homes">SM Homes</option>
                <option value="Elite">Elite Stays</option>
              </select>
            )}
          /> */}
          <button
            className="--view-room-btn"
            onClick={() => {
              navigate("/");
            }}
          >
            VIEW ROOM
          </button>
        </div>
      </div>
      <div className="register-form-block my-1">
        <form onSubmit={handleSubmit(onSubmit)} className="py-4">
          <div>
            <div className="new-register-input-block">
              <div className="register-container --new-room-container">
                <label className="BillingAddInputLabel --new-register-label-two">
                  ROOM NO
                </label>
                <div className="newRegisterInputBlockTwo">
                  <Controller
                    control={control}
                    name="roomNumber"
                    render={(field) => (
                      <BillingAddInputField
                        {...field}
                        placeholder="Room Number"
                        type="number"
                        newRegisterInput="newRegisterInput"
                      />
                    )}
                    rules={{ required: true }}
                  />
                </div>
              </div>
            </div>
            <div className="new-register-input-block">
              <div className="register-container --new-room-container">
                <label className="BillingAddInputLabel --new-register-label-two">
                  NO OF BEDS
                </label>
                <div className="newRegisterInputBlockTwo">
                  <Controller
                    control={control}
                    name="NoOfBeds"
                    render={(field) => (
                      <BillingAddInputField
                        {...field}
                        placeholder="No of Beds"
                        type="number"
                        newRegisterInput="newRegisterInput"
                      />
                    )}
                    rules={{ required: true }}
                  />
                </div>
              </div>
            </div>
            <div className="new-register-input-block">
              <div className="register-container --new-room-container">
                <label className="BillingAddInputLabel --new-register-label-two">
                  ROOM TYPE
                </label>
                <div className="newRegisterInputBlockTwo">
                  <Controller
                    name="roomType"
                    control={control}
                    defaultValue=""
                    rules={{ required: true }} // Example: Add validation rules
                    render={({ field }) => (
                      <select {...field} className="newRegisterInput">
                        <option value="">Room Type</option>
                        <option value="With AC">With AC</option>
                        <option value="Without AC">Without AC</option>
                      </select>
                    )}
                  />
                </div>
              </div>
            </div>
            {/* <div className="new-register-input-block">
              <div className="register-container --new-room-container">
                <label className="BillingAddInputLabel --new-register-label-two">
                  FOOD OPTION
                </label>
                <div className="newRegisterInputBlockTwo">
                  <Controller
                    name="foodOption"
                    control={control}
                    defaultValue=""
                    rules={{ required: true }} // Example: Add validation rules
                    render={({ field }) => (
                      <select {...field} className="newRegisterInput">
                        <option value="">Select Food Option</option>
                        <option value="With Food">With Food</option>
                        <option value="Without Food">Without Food</option>
                      </select>
                    )}
                  />
                </div>
              </div>
            </div> */}
            <div className="new-register-input-block">
              <div className="register-container --new-room-container">
                <label className="BillingAddInputLabel --new-register-label-two">
                  EB NUMBER
                </label>
                <div className="newRegisterInputBlockTwo">
                  <Controller
                    control={control}
                    name="ebNo"
                    render={(field) => (
                      <BillingAddInputField
                        {...field}
                        placeholder="EB NUMBER"
                        type="text"
                        newRegisterInput="newRegisterInput"
                      />
                    )}
                    rules={{ required: true }}
                  />
                </div>
              </div>
            </div>

            <div className="new-register-input-block">
              <div className="register-btn-block">
                <button className="register-submit-btn">
                  {isLoading ? <i class="fa fa-spinner fa-spin"></i> : "Submit"}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>

      <PopUpModal isOpen={addRoomPopup === null}>
        <div className="p-0 m-0 popupBoxBack">
          <div
            style={{ width: "15px", height: "15px", cursor: "pointer" }}
            onClick={() => {
              setAddRoomPopup(null);
            }}
          >
            <img src="/assets/images/close.png" className="popUpBackGround" />
          </div>
        </div>

        <div className="d-flex flex-column justify-content-center align-items-center">
          <span className="popUpContent p-2">Thank you!</span>
          <span className="popUpContentPara pb-2">
            Room added successfully..
          </span>
          <button
            onClick={() => {
              navigate("/");
            }}
          >
            OK
          </button>
        </div>
      </PopUpModal>
      <PopUpModal isOpen={showErrorPopUp === null}>
        <div className="p-0 m-0 popupBoxBack">
          <div
            style={{ width: "15px", height: "15px", cursor: "pointer" }}
            onClick={() => {
              setErrorPopUp(null);
            }}
          >
            <img src="/assets/images/close.png" className="popUpBackGround" />
          </div>
        </div>

        <div className="d-flex flex-column justify-content-center align-items-center">
          <span className="popUpContentPara pt-3 pb-2">
            {errroMessage && errroMessage}
          </span>
          <button
            onClick={() => {
              setErrorPopUp(null);
            }}
          >
            OK
          </button>
        </div>
      </PopUpModal>
    </>
  );
};

export default NewRoomForm;
