import React, { useEffect, useState } from "react";
import BillingAddInputField from "../BillingAddInputField";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useMutation } from "react-query";
import axios from "axios";
import PopUpModal from "../PopUpModal";

const LoginRegisterComp = () => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    watch,
  } = useForm();
  const { state } = useLocation();
  console.log("STATE", state);
  const [isShow, setIsShow] = useState(true);
  const [isNewPass, setIsNewPass] = useState(true);
  const navigate = useNavigate();
  const [updatePopup, setUpdatePopup] = useState(null);
  const [addRegisterPopup, setAddRegisterPopup] = useState(null);
  const { mutate, isLoading, isError, isSuccess } = useMutation(
    (postData) =>
      axios.post(
        `https://api.2kvirtualworld.com/hostelBillingmanagement-0.0.1-SNAPSHOT/registers/createRegister`,
        postData
      ),
    {
      onSuccess: () => {
        console.log("user added successfully..");
        setAddRegisterPopup(1);
      },
      onError: (error) => {
        console.error("Mutation failed:", error);
      },
    }
  );
  const upData = useMutation(
    (postData) =>
      axios.put(
        `https://api.2kvirtualworld.com/hostelBillingmanagement-0.0.1-SNAPSHOT/registers/${
          state && state.id
        }`,
        postData
      ),
    {
      onSuccess: () => {
        // setModalStatus("Packing List has been updated successfully");
        // togglePopup();
        setUpdatePopup(1);
      },
      onError: (error) => {
        console.error("Mutation failed:", error);
      },
    }
  );
  useEffect(() => {
    setValue("customerName", state?.customerName);
    setValue("emailId", state?.emailId);
    setValue("customerPhno", state?.customerPhno);
    setValue("shopName", state?.shopName);
    setValue("companyAddress", state?.companyAddress);
    setValue("bankName", state?.bankName);
    setValue("accountNumber", state?.accountNumber);
    setValue("accountType", state?.accountType);
    setValue("ifscCode", state?.ifscCode);
    setValue("branch", state?.branch);
    setValue("registerNumber", state?.registerNumber);
    setValue("password", state?.password);
    setValue("confirmPassword", state?.confirmPassword);
  }, []);
  const onSubmit = (data) => {
    let formData;
    formData = {
      customerName: data?.customerName,
      emailId: data?.emailId,
      customerPhno: data?.customerPhno,
      shopName: data?.shopName,
      companyAddress: data?.companyAddress,
      bankName: data?.bankName,
      accountNumber: data?.accountNumber,
      accountType: data?.accountType,
      ifscCode: data?.ifscCode,
      branch: data?.branch,
      registerNumber: data?.registerNumber,
      password: data?.password,
      confirmPassword: data?.confirmPassword,
    };
    if (state && state?.id) {
      upData.mutate(formData);
    } else if (data?.password == data?.confirmPassword) {
      mutate(formData);
    } else {
      alert("password do not match");
    }
  };
  return (
    <>
      <div className="register-heading-block">
        <div className="d-flex align-items-center">
          <img
            src="/assets/images/back-arrow.png"
            alt="no-image"
            className="arrow-img-icon"
            onClick={() => {
              navigate(-1);
            }}
          />
          <h5 className="view-bill-heading --register-head pt-2">
            CUSTOMER REGISTER
          </h5>
        </div>

        <button
          className="view-customer-register"
          onClick={() => {
            navigate("/viewCustomerRegister");
          }}
        >
          View Customer
        </button>
      </div>
      <div className="register-form-block my-1">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="new-register-input-block --loginRegisterBlock">
            <div className="register-container">
              <label className="BillingAddInputLabel --new-register-label-two">
                CUSTOMER NAME
              </label>
              <div className="newRegisterInputBlockTwo">
                <Controller
                  control={control}
                  name="customerName"
                  render={(field) => (
                    <BillingAddInputField
                      {...field}
                      placeholder="Name"
                      type="text"
                      newRegisterInput="newRegisterInput"
                    />
                  )}
                  rules={{ required: true }}
                />
              </div>
            </div>
            <div className="register-container">
              <label className="BillingAddInputLabel --new-register-label-two">
                EMAIL ID
              </label>
              <div className="newRegisterInputBlockTwo">
                <Controller
                  control={control}
                  name="emailId"
                  render={(field) => (
                    <BillingAddInputField
                      {...field}
                      placeholder="Email Id"
                      type="emailid"
                      newRegisterInput="newRegisterInput"
                    />
                  )}
                  rules={{ required: true }}
                />
              </div>
            </div>
          </div>
          <div className="new-register-input-block --loginRegisterBlock">
            <div className="register-container">
              <label className="BillingAddInputLabel --new-register-label-two">
                CUSTOMER MOBILE NUMBER
              </label>
              <div className="newRegisterInputBlockTwo">
                <Controller
                  control={control}
                  name="customerPhno"
                  render={(field) => (
                    <BillingAddInputField
                      {...field}
                      placeholder="Phone Number"
                      type="tele"
                      newRegisterInput="newRegisterInput"
                    />
                  )}
                  rules={{ required: true }}
                />
              </div>
            </div>
            <div className="register-container">
              <label className="BillingAddInputLabel --new-register-label-two">
                SHOP NAME
              </label>
              <div className="newRegisterInputBlockTwo">
                <Controller
                  name="shopName"
                  control={control}
                  defaultValue=""
                  rules={{ required: true }} // Example: Add validation rules
                  render={({ field }) => (
                    <select {...field} className="newRegisterInput">
                      <option value="">SELECT A HOSTEL</option>
                      <option value="SMHomes">SMHomes</option>
                      <option value="EliteStays">EliteStays</option>
                    </select>
                  )}
                />
              </div>
            </div>
          </div>
          <div className="new-register-input-block --loginRegisterBlock">
            <div className="register-container">
              <label className="BillingAddInputLabel --new-register-label-two">
                ADDRESS
              </label>
              <div className="newRegisterInputBlockTwo">
                <Controller
                  control={control}
                  name="companyAddress"
                  render={(field) => (
                    <BillingAddInputField
                      {...field}
                      placeholder="Address"
                      type="text"
                      newRegisterInput="newRegisterInput"
                    />
                  )}
                  rules={{ required: true }}
                />
              </div>
            </div>
            <div className="register-container">
              <label className="BillingAddInputLabel --new-register-label-two">
                BANK NAME
              </label>
              <div className="newRegisterInputBlockTwo">
                <Controller
                  control={control}
                  name="bankName"
                  render={(field) => (
                    <BillingAddInputField
                      {...field}
                      placeholder="BANK NAME"
                      type="text"
                      newRegisterInput="newRegisterInput"
                    />
                  )}
                  rules={{ required: true }}
                />
              </div>
            </div>
          </div>
          <div className="new-register-input-block --loginRegisterBlock">
            <div className="register-container">
              <label className="BillingAddInputLabel --new-register-label-two">
                ACCOUNT NUMBER
              </label>
              <div className="newRegisterInputBlockTwo">
                <Controller
                  control={control}
                  name="accountNumber"
                  render={(field) => (
                    <BillingAddInputField
                      {...field}
                      placeholder="Account Number"
                      type="number"
                      newRegisterInput="newRegisterInput"
                    />
                  )}
                  rules={{ required: true }}
                />
              </div>
            </div>
            <div className="register-container">
              <label className="BillingAddInputLabel --new-register-label-two">
                ACCOUNT TYPE
              </label>
              <div className="newRegisterInputBlockTwo">
                <Controller
                  control={control}
                  name="accountType"
                  render={(field) => (
                    <BillingAddInputField
                      {...field}
                      placeholder="Account Type"
                      type="text"
                      newRegisterInput="newRegisterInput"
                    />
                  )}
                  rules={{ required: true }}
                />
              </div>
            </div>
          </div>
          <div className="new-register-input-block --loginRegisterBlock">
            <div className="register-container">
              <label className="BillingAddInputLabel --new-register-label-two">
                IFSC CODE
              </label>
              <div className="newRegisterInputBlockTwo">
                <Controller
                  control={control}
                  name="ifscCode"
                  render={(field) => (
                    <BillingAddInputField
                      {...field}
                      placeholder="Ifsc Code"
                      type="text"
                      newRegisterInput="newRegisterInput"
                    />
                  )}
                  rules={{ required: true }}
                />
              </div>
            </div>
            <div className="register-container">
              <label className="BillingAddInputLabel --new-register-label-two">
                BRANCH
              </label>
              <div className="newRegisterInputBlockTwo">
                <Controller
                  control={control}
                  name="branch"
                  render={(field) => (
                    <BillingAddInputField
                      {...field}
                      placeholder="Branch"
                      type="text"
                      newRegisterInput="newRegisterInput"
                    />
                  )}
                  rules={{ required: true }}
                />
              </div>
            </div>
          </div>
          <div className="new-register-input-block --loginRegisterBlock">
            <div className="register-container">
              <label className="BillingAddInputLabel --new-register-label-two">
                PASSWORD
              </label>
              <div className="loginregisterpassblock">
                <div style={{ width: "100%" }}>
                  <Controller
                    control={control}
                    name="password"
                    render={(field) => (
                      <BillingAddInputField
                        {...field}
                        placeholder="Password"
                        type={`${!isNewPass ? "text" : "password"}`}
                        loginregisterpass="loginregisterpass"
                      />
                    )}
                    rules={{ required: true }}
                  />
                </div>

                <div
                  className="passwordBlock"
                  onClick={() => {
                    setIsNewPass(!isNewPass);
                  }}
                >
                  {!isNewPass ? (
                    <img
                      src="/assets/images/visibility_on.png"
                      className="image_icon"
                      alt="no-image"
                    />
                  ) : (
                    <img
                      src="/assets/images/visibility_off.png"
                      className="image_icon"
                      alt="no-image"
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="register-container">
              <label className="BillingAddInputLabel --new-register-label-two">
                CONFIRM PASSWORD
              </label>
              <div className="loginregisterpassblock">
                <div style={{ width: "100%" }}>
                  <Controller
                    control={control}
                    name="confirmPassword"
                    render={(field) => (
                      <BillingAddInputField
                        {...field}
                        placeholder="Confirm Password"
                        type={`${!isShow ? "text" : "password"}`}
                        loginregisterpass="loginregisterpass"
                      />
                    )}
                    rules={{ required: true }}
                  />
                </div>

                <div
                  className="passwordBlock"
                  onClick={() => {
                    setIsShow(!isShow);
                  }}
                >
                  {!isShow ? (
                    <img
                      src="/assets/images/visibility_on.png"
                      className="image_icon"
                      alt="no-image"
                    />
                  ) : (
                    <img
                      src="/assets/images/visibility_off.png"
                      className="image_icon"
                      alt="no-image"
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="new-register-input-block --loginRegisterBlock">
            <div className="register-container">
              <label className="BillingAddInputLabel --new-register-label-two">
                REGISTER NUMBER
              </label>
              <div className="newRegisterInputBlockTwo">
                <Controller
                  control={control}
                  name="registerNumber"
                  render={(field) => (
                    <BillingAddInputField
                      {...field}
                      placeholder="Register Number"
                      type="text"
                      newRegisterInput="newRegisterInput"
                    />
                  )}
                  rules={{ required: true }}
                />
              </div>
            </div>
          </div>

          <div className="new-register-input-block --loginRegisterBlock">
            <div className="register-btn-block ">
              <button className="register-submit-btn">
                {isLoading ? <i class="fa fa-spinner fa-spin"></i> : "Submit"}
              </button>
            </div>
          </div>
        </form>
      </div>
      <PopUpModal isOpen={updatePopup === null}>
        <div className="p-0 m-0 popupBoxBack">
          <div
            style={{ width: "15px", height: "15px", cursor: "pointer" }}
            onClick={() => {
              setUpdatePopup(null);
            }}
          >
            <img src="/assets/images/close.png" className="popUpBackGround" />
          </div>
        </div>
        <div className=" pt-2 d-flex flex-column justify-content-center align-items-center">
          <p className="popupDeleteUser  m-0 p-o">
            Customer has been updated successfully..
          </p>
          <button
            onClick={() => {
              navigate("/viewCustomerRegister");
            }}
          >
            OK
          </button>
        </div>
      </PopUpModal>
      <PopUpModal isOpen={addRegisterPopup === null}>
        <div className="popup-overlay">
          <div className="confirmation-popup">
            <div className="p-0 m-0 popupBoxBack">
              <div
                style={{ width: "15px", height: "15px", cursor: "pointer" }}
                onClick={() => {
                  setAddRegisterPopup(null);
                }}
              >
                <img
                  src="/assets/images/close.png"
                  className="popUpBackGround"
                />
              </div>
            </div>

            <div className="d-flex flex-column justify-content-center align-items-center">
              <span className="popUpContent p-2">Thank you!</span>
              <span className="popUpContentPara pb-3">
                User added successfully..
              </span>
              <button
                onClick={() => {
                  navigate("/viewCustomerRegister");
                }}
              >
                OK
              </button>
            </div>
          </div>
        </div>
      </PopUpModal>
    </>
  );
};

export default LoginRegisterComp;
