import React from "react";
import Sidebar from "../../components/Sidebar";
import ViewCustomer from "../../components/viewregistercomp/ViewCustomer";

const ViewCustomerRegister = () => {
  return (
    <section className="viewBillMainSection">
      <div className="container-fluid">
        <div className="row p-0">
          <div className="col-lg-2 p-0 m-0">
            <Sidebar />
          </div>
          <div className="col-lg-10 py-3 px-4 m-0">
            <ViewCustomer />
          </div>
        </div>
      </div>
    </section>
  );
};

export default ViewCustomerRegister;
