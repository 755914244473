import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "react-query";
import axios from "axios";
import PopUpModal from "../components/PopUpModal";
import Sidebar from "../components/Sidebar";
import moment from "moment/moment";

const ViewRegister = () => {
  const queryClient = useQueryClient();
  const [addSubTicketPopUp, setAddSubTicketPopUp] = useState(null);
  const [deletePopup, setDeletePopUp] = useState(null);
  const [deleteId, setDeleteId] = useState(null);
  const navigate = useNavigate();
  const itemsPerPage = 5;
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [filter, setFilter] = useState("");
  const [totalcount, setTotalCount] = useState(0);
  const [activeCount, setActiveCount] = useState(0);
  const [InactiveCount, setInactiveCount] = useState(0);

  let hostelName = localStorage.getItem("hostelName");
  console.log("HOSTEL NAME : ", hostelName);

  const getViewRegister = async () => {
    return await axios.get(
      `https://api.2kvirtualworld.com/hostelBillingmanagement-0.0.1-SNAPSHOT/newregister/getAllNewRegister/${hostelName}`
    );
  };

  const { data, isLoading, isError } = useQuery(
    "getViewRegister",
    getViewRegister
  );

  let details = data && data?.data;
  console.log("DETAILS", details);

  const [filteredData, setFilteredData] = useState([]);
  useEffect(() => {
    const applyFilterAndSearch = () => {
      if (!details) {
        setFilteredData([]);
        return;
      }

      // Convert searchTerm to lowercase once for consistency
      const lowercasedSearchTerm = searchTerm?.toLowerCase() || "";

      const result = details.filter((item) => {
        // Ensure that the filter criteria is applied
        const matchesFilter = filter ? item?.status === filter : true;

        // Normalize item fields to lowercase for comparison
        const itemRoomNo = (item?.roomNo ?? "").toString().toLowerCase();
        const itemName = (item?.name ?? "").toString().toLowerCase();

        // Check if any of the normalized fields match the searchTerm
        const matchesSearch =
          !searchTerm ||
          itemRoomNo.startsWith(lowercasedSearchTerm) ||
          itemName.startsWith(lowercasedSearchTerm);

        return matchesFilter && matchesSearch;
      });

      setFilteredData(result);
    };

    applyFilterAndSearch();
  }, [details, filter, searchTerm]);

  // Pagination logic
  const totalPages = Math.ceil(filteredData?.length / itemsPerPage);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData?.slice(indexOfFirstItem, indexOfLastItem);

  // Pagination handlers
  const isPrevDisabled = currentPage === 1;
  const isNextDisabled = currentPage === totalPages || totalPages === 0;

  const handlePrevPage = () => {
    if (!isPrevDisabled) setCurrentPage(currentPage - 1);
  };

  const handleNextPage = () => {
    if (!isNextDisabled) setCurrentPage(currentPage + 1);
  };
  const deleteItem = async (id) => {
    await axios.delete(
      `https://api.2kvirtualworld.com/hostelBillingmanagement-0.0.1-SNAPSHOT/newregister/${hostelName}/${id}`
    );
  };

  const mutation = useMutation(deleteItem, {
    onSuccess: () => {
      queryClient.invalidateQueries("getViewBill");

      setDeletePopUp(1); // Show success popup
    },
    onError: (err) => {
      console.log("Error", err);
    },
  });

  const handleDelete = (id) => {
    mutation.mutate(id);
    setAddSubTicketPopUp(null); // Close confirmation popup
  };
  useEffect(() => {
    // Initialize totals
    let totalreg = 0;
    let activeCount = 0;
    let inactiveCount = 0;

    // Compute totals
    currentItems?.forEach((item) => {
      totalreg += 1;
      if (item?.status == "Active") {
        activeCount += 1;
      } else if (item?.status == "Inactive") {
        inactiveCount += 1;
      }
    });

    // Update state with computed totals
    setTotalCount(totalreg);
    setActiveCount(activeCount);
    setInactiveCount(inactiveCount);
  }, [currentItems]);
  useEffect(() => {
    // Reset to page 1 when search term changes
    setCurrentPage(1);
  }, [searchTerm]);

  return (
    <>
      <section className="viewBillMainSection">
        <div className="container-fluid ">
          <div className="row p-0">
            <div className="col-lg-2 p-0 m-0">
              <Sidebar />
            </div>
            <div className="col-lg-10 py-3 px-4 m-0">
              <div className="view-register-head-block mb-3 mt-1">
                <div className="view-register-title">
                  <img
                    src="/assets/images/back-arrow.png"
                    className="arrow-img-icon"
                    alt="no-image"
                    onClick={() => navigate(-1)}
                  />
                  <h5 className="view-bill-heading pt-2">VIEW REGISTER</h5>
                </div>
                <div className="view-bill-search-container">
                  <select
                    className="view-bill-status"
                    onChange={(e) => setFilter(e.target.value)}
                    value={filter}
                  >
                    <option value="">All</option>
                    <option value="Active">Active</option>
                    <option value="Inactive">Inactive</option>
                  </select>
                  <input
                    type="search"
                    className="view-bill-search"
                    placeholder="search"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </div>
              </div>
              <div className="status-row-bar">
                <div className="bill-status-bar --first-bar">
                  <p className="bill-status-content">ACTIVE</p>
                  <p className="bill-status-count">{activeCount}</p>
                </div>
                <div className="bill-status-bar">
                  <p className="bill-status-content">INACTIVE</p>
                  <p className="bill-status-count">{InactiveCount}</p>
                </div>
                <div className="bill-status-bar --last-bar">
                  <p className="bill-status-content">TOTAL</p>
                  <p className="bill-status-count">{totalcount}</p>
                </div>{" "}
              </div>
              <div className="view-bill-main-block">
                <div className="table-responsive table-container">
                  <table className="table ">
                    <thead>
                      <tr>
                        <th className="view-bill-head --first-head">NAME</th>
                        <th className="view-bill-head">ROOM NO</th>
                        <th className="view-bill-head">BED NO</th>
                        <th className="view-bill-head">MOBILE NO</th>
                        {/* <th className="view-bill-head">EMERGENCY No</th> */}
                        <th className="view-bill-head">EMAILID</th>
                        <th className="view-bill-head">DATE OF BIRTH</th>
                        <th className="view-bill-head">DATE OF JOINING</th>
                        <th className="view-bill-head">FOOD OPTION</th>
                        <th className="view-bill-head">ADVANCE PAYMENT</th>
                        <th className="view-bill-head">RENT FOR MONTH</th>
                        <th className="view-bill-head">ADDRESS</th>
                        <th className="view-bill-head">STATUS</th>
                        <th className="view-bill-head">DATE OF LEAVING</th>
                        <th className="view-bill-head --last-head">EDIT</th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentItems && currentItems.length > 0 ? (
                        currentItems.map((item, index) => (
                          <tr key={index}>
                            <td className="view-bill-data">{item?.name}</td>
                            <td className="view-bill-data">{item?.roomNo}</td>
                            <td className="view-bill-data">{item?.bedNo}</td>
                            <td className="view-bill-data">{item?.mobileNo}</td>
                            {/* <td className="view-bill-data">
                              {item?.emergencyNo}
                            </td> */}
                            <td className="view-bill-data">{item?.emailId}</td>
                            <td className="view-bill-data">
                              {item?.dateOfBirth}
                            </td>
                            <td className="view-bill-data">
                              {item?.dateOfJoining}
                            </td>
                            <td className="view-bill-data">
                              {item?.selectFoodOption}
                            </td>
                            <td className="view-bill-data">
                              {item?.advanceAmount}
                            </td>
                            <td className="view-bill-data">
                              {item?.rentForMonth}
                            </td>
                            <td className="view-bill-data">{item?.address}</td>
                            <td className="view-bill-data">{item?.status}</td>
                            <td className="view-bill-data">
                              {item?.status=="Inactive"
                                ? moment(item?.selectHostel).format(
                                    "DD/MM/YYYY"
                                  )
                                : "---"}
                            </td>

                            <td className="view-bill-data">
                              <img
                                src="/assets/images/edit-icon.png"
                                className="--image-click"
                                alt="no-image"
                                onClick={() =>
                                  navigate("/editRegister", { state: item })
                                }
                              />
                              <img
                                src="/assets/images/delete-icon.png"
                                className="--image-click"
                                alt="no-image"
                                onClick={() => {
                                  setAddSubTicketPopUp(true);
                                  setDeleteId(item?.id);
                                }}
                              />
                              <img
                                src="/assets/images/bill.png"
                                className="--image-click"
                                alt="no-image"
                                onClick={() => {
                                  navigate("/newBill", { state: item });
                                }}
                              />
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="13">No results found</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                <div className="d-flex justify-content-between pt-2 px-1">
                  <button
                    onClick={handlePrevPage}
                    disabled={isPrevDisabled}
                    className="arrow-btn"
                  >
                    &lt;
                  </button>
                  <button
                    onClick={handleNextPage}
                    disabled={isNextDisabled}
                    className="arrow-btn"
                  >
                    &gt;
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <PopUpModal isOpen={addSubTicketPopUp === null}>
        <div className="p-0 m-0 popupBoxBack">
          <div
            style={{ width: "15px", height: "15px", cursor: "pointer" }}
            onClick={() => setAddSubTicketPopUp(null)}
          >
            <img src="/assets/images/close.png" className="popUpBackGround" />
          </div>
        </div>
        <div className="d-flex flex-column justify-content-center align-items-center">
          <p className="popupDeleteUser m-0 px-1">
            Are you sure you want to delete the user detail?
          </p>
          <div className="d-flex justify-content-center align-items-center gap-2">
            <button
              onClick={() => {
                handleDelete(deleteId);
              }}
            >
              {isLoading ? <i class="fa fa-spinner fa-spin"></i> : "YES"}
            </button>
            <button onClick={() => setAddSubTicketPopUp(null)}>NO</button>
          </div>
        </div>
      </PopUpModal>

      <PopUpModal isOpen={deletePopup === null}>
        <div className="p-0 m-0 popupBoxBack">
          <div
            style={{ width: "15px", height: "15px", cursor: "pointer" }}
            onClick={() => setDeletePopUp(null)}
          >
            <img src="/assets/images/close.png" className="popUpBackGround" />
          </div>
        </div>
        <div className="pt-2 d-flex flex-column justify-content-center align-items-center">
          <p className="popupDeleteUser m-0 p-0">
            User has been deleted successfully.
          </p>
          <button
            onClick={() => {
              setDeletePopUp(null);
              window.location.reload();
            }}
          >
            OK
          </button>
        </div>
      </PopUpModal>
    </>
  );
};

export default ViewRegister;
