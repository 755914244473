import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import BillingAddInputField from "../BillingAddInputField";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import moment from "moment";
import { useMutation } from "react-query";
import axios from "axios";
import { useLocation } from "react-router-dom";
import PopUpModal from "../PopUpModal";
const Editroom = () => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    watch,
  } = useForm();
  const [updatePopup, setUpdatePopup] = useState(null);
  const navigate = useNavigate();
  const { state } = useLocation();
  let hostelName = localStorage.getItem("hostelName");
  // console.log("HOSTEL NAME : ", hostelName);
  console.log("STATE", state);

  useEffect(() => {
    setValue("roomNumber", state?.roomNo);
    setValue("NoOfBeds", state?.noOfBeds);
    setValue("roomType", state?.roomType);
    setValue("ebNo", state?.ebNumber);
    setValue("foodOption", state?.foodOption);
  }, []);
  const { mutate, isLoading, isError, isSuccess } = useMutation(
    (postdata) =>
      axios.put(
        `https://api.2kvirtualworld.com/hostelBillingmanagement-0.0.1-SNAPSHOT/rooms/updateNewRoom/${hostelName}/${
          state && state?.id
        }`,
        postdata
      ),
    {
      onSuccess: (val) => {
        console.log("data updated successfully...");
        setUpdatePopup(1);
      },
      onError: (error) => {
        console.error("Mutation failed:", error);
      },
    }
  );
  const onSubmit = (data) => {
    let formData;

    if (state && state.id != null) {
      formData = {
        roomNo: data?.roomNumber,
        noOfBeds: data?.NoOfBeds,
        roomType: data?.roomType,
        ebNumber: data?.ebNo,
        selectHostel: "Hostel 1",
        foodOption: "with food"
      };
    }
    mutate(formData);
  };

  return (
    <>
      <div className="register-heading-block">
        <div className="new-room-heading">
          <img
            src="/assets/images/back-arrow.png"
            alt="no-image"
            className="arrow-img-icon"
            onClick={() => {
              navigate(-1);
            }}
          />
          <h5 className="view-bill-heading --register-head pt-2">EDIT ROOM</h5>
        </div>
      </div>
      <div className="register-form-block ">
        <form onSubmit={handleSubmit(onSubmit)} className="py-4">
          <div>
            <div className="new-register-input-block">
              <div className="register-container --new-room-container">
                <label className="BillingAddInputLabel --new-register-label-two">
                  ROOM NO
                </label>
                <div className="newRegisterInputBlockTwo">
                  <Controller
                    control={control}
                    name="roomNumber"
                    render={(field) => (
                      <BillingAddInputField
                        {...field}
                        placeholder="Room Number"
                        type="number"
                        newRegisterInput="newRegisterInput"
                      />
                    )}
                    rules={{ required: true }}
                  />
                </div>
              </div>
            </div>
            <div className="new-register-input-block">
              <div className="register-container --new-room-container">
                <label className="BillingAddInputLabel --new-register-label-two">
                  NO OF BED
                </label>
                <div className="newRegisterInputBlockTwo">
                  <Controller
                    control={control}
                    name="NoOfBeds"
                    render={(field) => (
                      <BillingAddInputField
                        {...field}
                        placeholder="No of Beds"
                        type="number"
                        newRegisterInput="newRegisterInput"
                      />
                    )}
                    rules={{ required: true }}
                  />
                </div>
              </div>
            </div>
            <div className="new-register-input-block">
              <div className="register-container --new-room-container">
                <label className="BillingAddInputLabel --new-register-label-two">
                  ROOM TYPE
                </label>
                <div className="newRegisterInputBlockTwo">
                  <Controller
                    name="roomType"
                    control={control}
                    defaultValue=""
                    rules={{ required: true }} // Example: Add validation rules
                    render={({ field }) => (
                      <select {...field} className="newRegisterInput">
                        <option value="">Room Type</option>
                        <option value="With AC">With AC</option>
                        <option value="Without AC">Without AC</option>
                      </select>
                    )}
                  />
                </div>
              </div>
            </div>
        
            <div className="new-register-input-block">
              <div className="register-container --new-room-container">
                <label className="BillingAddInputLabel --new-register-label-two">
                  EB NUMBER
                </label>
                <div className="newRegisterInputBlockTwo">
                  <Controller
                    control={control}
                    name="ebNo"
                    render={(field) => (
                      <BillingAddInputField
                        {...field}
                        placeholder="EB NUMBER"
                        type="text"
                        newRegisterInput="newRegisterInput"
                      />
                    )}
                    rules={{ required: true }}
                  />
                </div>
              </div>
            </div>

            <div className="new-register-input-block">
              <div className="register-btn-block ">
                <button className="register-submit-btn">
                  {isLoading ? <i class="fa fa-spinner fa-spin"></i> : "Submit"}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>

      <PopUpModal isOpen={updatePopup === null}>
        <div className="p-0 m-0 popupBoxBack">
          <div
            style={{ width: "15px", height: "15px", cursor: "pointer" }}
            onClick={() => {
              setUpdatePopup(null);
            }}
          >
            <img src="/assets/images/close.png" className="popUpBackGround" />
          </div>
        </div>
        <div className=" pt-2 d-flex flex-column justify-content-center align-items-center">
          <p className="popupDeleteUser  m-0 p-o">
            Room has been updated successfully..
          </p>
          <button
            onClick={() => {
              navigate("/");
            }}
          >
            OK
          </button>
        </div>
      </PopUpModal>
    </>
  );
};

export default Editroom;
