import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import Login from "./pages/Login";
import RentBill from "./pages/RentBill";
import BillingInvoice from "./pages/BillingInvoice";
import { QueryClient, QueryClientProvider, useQuery } from "react-query";
import ViewBill from "./pages/ViewBill";
import EditBill from "./pages/EditBill";
import NewRegister from "./pages/NewRegister";
import ViewRegister from "./pages/ViewRegister";
import EditRegister from "./pages/EditRegister";
import NewRoom from "./pages/NewRoom";
import ViewRoom from "./pages/ViewRoom";
import ViewBeds from "./components/viewroomcomp/ViewBeds";
import EditRoomPage from "./pages/editroomscreen/EditRoomPage";
import LoginRegister from "./pages/loginregisterscreen/LoginRegister";
import ViewCustomerRegister from "./pages/viewregisterscreen/ViewCustomerRegister";
// import Login from "./pages/Login";

const App = () => {
  const queryClient = new QueryClient();
  let logincheck = localStorage.getItem("login");
  // let logincheck = "Login successful";
  // console.log("logincheck", logincheck);
  return (
    <>
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          {logincheck === "Login successful" ? (
            <>
              <Routes>
                <Route path="/" element={<ViewRoom />} />
                <Route path="/billingInvoice" element={<BillingInvoice />} />
                <Route path="/viewBill" element={<ViewBill />} />
                <Route path="/editBill" element={<EditBill />} />
                <Route path="/newRegister" element={<NewRegister />} />
                <Route path="/viewRegister" element={<ViewRegister />} />
                <Route path="/editRegister" element={<EditRegister />} />
                <Route path="/newRoom" element={<NewRoom />} />
                <Route path="/newBill" element={<RentBill />} />
                <Route path="/viewBeds" element={<ViewBeds />} />
                <Route path="/editroom" element={<EditRoomPage />} />
                <Route path="/loginRegister" element={<LoginRegister />} />
                <Route
                  path="/viewCustomerRegister"
                  element={<ViewCustomerRegister/> }
                />
              </Routes>
            </>
          ) : (
            <>
              <Routes>
                <Route path="/" element={<Login />} />
              </Routes>
            </>
          )}
        </QueryClientProvider>
      </BrowserRouter>
    </>
  );
};

export default App;
