import React from "react";
import NewRoomForm from "../components/newroomcomp/NewRoomForm";
import Sidebar from "../components/Sidebar";

const NewRoom = () => {
  return (
    <>
      <section className="registrationMainContainer">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-2 p-0 m-0">
              <Sidebar />
            </div>
            <div className="col-lg-10 px-4 py-2 m-0">
              <NewRoomForm />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default NewRoom;
