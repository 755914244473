import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useLocation } from "react-router-dom";
import BillingAddInputField from "../components/BillingAddInputField";

import PopUpModal from "../components/PopUpModal";

import { useMutation } from "react-query";
import axios from "axios";

import moment from "moment";
import Sidebar from "../components/Sidebar";
const EditBill = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    watch,
  } = useForm();
  const [updatePopup, setUpdatePopup] = useState(null);
  let hostelName = localStorage.getItem("hostelName");
  console.log("HOSTEL NAME : ", hostelName);
  const {
    currentAmount,
    rentForMonth,
    others,
    currentlyPaidAmount,
    powerUsedFrom,
    powerUsedTo,
  } = watch();
  // const currentamt = Number(powerUsedTo) - Number(powerUsedFrom);
  // const currentamounts = 6 * currentamt;

  const totalAmount = `${
    Number(currentAmount) + Number(rentForMonth) + Number(others)
  }`;
  const remainingbalance = `${
    Number(totalAmount) - Number(currentlyPaidAmount)
  }`;

  useEffect(() => {
    // setValue("currentAmount", currentamounts);
    setValue("total", totalAmount);
    setValue("remainingAmount", remainingbalance);
  }, [totalAmount, remainingbalance]);

  const { state } = useLocation();
  console.log("STATE", state);
  useEffect(() => {
    setValue("role", state?.role);
    setValue("date", state?.date);
    setValue("billNo", state?.billNo);
    setValue("name", state?.name);
    setValue("mobileNumber", state?.mobileNumber);
    setValue("email", state?.emailId);
    setValue("roomNo", state?.roomNo);
    setValue("rentForMonth", state?.rentForMonth);
    setValue("others", state?.others);
    setValue("total", state?.total);
    setValue("currentAmount", state?.currentAmount);
    setValue("currentlyPaidAmount", state?.currentlypaidAmount);
    setValue("remainingAmount", state?.remainingAmount);
  }, []);
  const { mutate, isLoading, isError, isSuccess } = useMutation(
    (postdata) =>
      axios.put(
        `https://api.2kvirtualworld.com/hostelBillingmanagement-0.0.1-SNAPSHOT/newbills/updateNewBill/${hostelName}/${
          state && state.id
        }`,
        postdata
      ),
    {
      onSuccess: (val) => {
        setUpdatePopup(1);
      },
      onError: (error) => {
        console.error("Mutation failed:", error);
      },
    }
  );
  const onSubmit = (data) => {
    let formData;
    if (state && state.id != null) {
      formData = {
        id: state.id,
        name: data?.name,

        date: moment(data?.date).format("YYYY-MM-DD"),
        mobileNumber: data?.mobileNumber,
        emailId: data?.email,
        roomNo: data?.roomNo,
        currentAmount: data?.currentAmount,
        others: data?.others,
        rentForMonth: data?.rentForMonth,
        role: data?.role,
        total: data?.total,
        billNo: data?.billNo,
        powerUsedFrom: 0,
        powerUsedTo: 0,
        currentlypaidAmount: data?.currentlyPaidAmount,
        remainingAmount: data?.remainingAmount,
      };
      localStorage.setItem("role", data?.role);
    }
    mutate(formData);
  };
  return (
    <>
      <section className="rentBillMainSection">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-2 p-0 m-0">
              <Sidebar />
            </div>
            <div className="col-lg-10  py-2 px-4">
              {/* <div className="new-bill-head-block">
                <div className="d-flex align-items-center">
                  <img
                    src="/assets/images/back-arrow.png"
                    style={{ width: "40px", height: "40px", cursor: "pointer" }}
                    alt="no-image"
                    onClick={() => {
                      navigate(-1);
                    }}
                  />
                  <h6 className="rentBillHeading pt-2">EDIT BILL</h6>
                </div>
                <Controller
                  name="role"
                  control={control}
                  defaultValue=""
                  rules={{ required: true }} // Example: Add validation rules
                  render={({ field }) => (
                    <select {...field} className="selectDropDown">
                      <option value="">SELECT A HOSTEL</option>
                      <option value="SM Homes">SM Homes</option>
                      <option value="Elite">Elite Stays</option>
                    </select>
                  )}
                />
              </div> */}
              <div className="new-bill-head-block">
                <div className="new-bill-heading-block">
                  <img
                    src="/assets/images/back-arrow.png"
                    className="arrow-img-icon"
                    alt="no-image"
                    onClick={() => {
                      navigate(-1);
                    }}
                  />
                  <h6 className="rentBillHeading pt-2">EDIT BILL</h6>
                </div>
                <div className="new-bill-search-block">
                  <Controller
                    name="role"
                    control={control}
                    defaultValue=""
                    rules={{ required: true }} // Example: Add validation rules
                    render={({ field }) => (
                      <select {...field} className="selectDropDown">
                        <option value="">SELECT A HOSTEL</option>
                        <option value="SM Homes">SM Homes</option>
                        <option value="Elite">Elite Stays</option>
                      </select>
                    )}
                  />
                </div>
              </div>
              <div className="py-3">
                <form
                  onSubmit={handleSubmit(onSubmit)}
                  className="rentalFormBlock"
                >
                  <div className=" py-2 px-4 billingMainInputBlock">
                    <div className="billingInputLabelBlock">
                      <label className="BillingAddInputLabel">Date</label>
                    </div>
                    <div className="billingInputFieldBlock">
                      <Controller
                        control={control}
                        name="date"
                        render={({ field }) => (
                          <DatePicker
                            className="bookingDateEditInput"
                            onChange={(date) => field.onChange(date)}
                            selected={field.value}
                            placeholderText="DD/MM/YY"
                             disabled="disabled"
                          />
                        )}
                        rules={{ required: true }}
                      />
                    </div>
                  </div>
                  <div className="py-2 px-4 billingMainInputBlock">
                    <div className="billingInputLabelBlock">
                      <label className="BillingAddInputLabel">Bill No</label>
                    </div>
                    <div className="billingInputFieldBlock">
                      <Controller
                        control={control}
                        name="billNo"
                        render={(field) => (
                          <BillingAddInputField
                            {...field}
                            placeholder="Bill Number"
                            type="number"
                            bookingEditInput="bookingEditInput"
                             disabled="disabled"
                          />
                        )}
                        rules={{ required: true }}
                      />
                    </div>
                  </div>
                  <div className="py-2 px-4 billingMainInputBlock">
                    <div className="billingInputLabelBlock">
                      <label className="BillingAddInputLabel">Name</label>
                    </div>
                    <div className="billingInputFieldBlock">
                      <Controller
                        control={control}
                        name="name"
                        render={(field) => (
                          <BillingAddInputField
                            {...field}
                            placeholder="Customer Name"
                            type="text"
                            bookingEditInput="bookingEditInput"
                            disabled="disabled"
                          />
                        )}
                        rules={{ required: true }}
                      />
                    </div>
                  </div>
                  <div className="py-2 px-4 billingMainInputBlock">
                    <div className="billingInputLabelBlock">
                      <label className="BillingAddInputLabel">
                        Mobile Number
                      </label>
                    </div>
                    <div className="billingInputFieldBlock">
                      <Controller
                        control={control}
                        name="mobileNumber"
                        render={(field) => (
                          <BillingAddInputField
                            {...field}
                            placeholder="Mobile Number"
                            type="number"
                            bookingEditInput="bookingEditInput"
                             disabled="disabled"
                          />
                        )}
                        rules={{ required: true }}
                      />
                    </div>
                  </div>
                  <div className="py-2 px-4 billingMainInputBlock">
                    <div className="billingInputLabelBlock">
                      <label className="BillingAddInputLabel">Email Id</label>
                    </div>
                    <div className="billingInputFieldBlock">
                      <Controller
                        control={control}
                        name="email"
                        render={(field) => (
                          <BillingAddInputField
                            {...field}
                            placeholder="Email Id"
                            type="emailid"
                            bookingEditInput="bookingEditInput"
                             disabled="disabled"
                          />
                        )}
                        rules={{ required: true }}
                      />
                    </div>
                  </div>
                  <div className="py-2 px-4 billingMainInputBlock">
                    <div className="billingInputLabelBlock">
                      <label className="BillingAddInputLabel">Room No</label>
                    </div>
                    <div className="billingInputFieldBlock">
                      <Controller
                        control={control}
                        name="roomNo"
                        render={(field) => (
                          <BillingAddInputField
                            {...field}
                            placeholder="Room No"
                            bookingEditInput="bookingEditInput"
                            type="number"
                             disabled="disabled"
                          />
                        )}
                        rules={{ required: true }}
                      />
                    </div>
                  </div>
                  <div className="py-2 px-4 billingMainInputBlock">
                    <div className="billingInputLabelBlock">
                      <label className="BillingAddInputLabel">EB Amount</label>
                    </div>
                    <div className="billingInputFieldBlock">
                      <Controller
                        control={control}
                        name="currentAmount"
                        render={(field) => (
                          <BillingAddInputField
                            {...field}
                            placeholder="currentAmount"
                            bookingEditInput="bookingEditInput"
                            type="number"
                             disabled="disabled"
                          />
                        )}
                        rules={{ required: true }}
                      />
                    </div>
                  </div>

                  <div className="py-2 px-4 billingInputBlock">
                    <div className="billingInputLabelBlock">
                      <label className="BillingAddInputLabel">
                        Rent For Month
                      </label>
                    </div>
                    <div className="bill-rent-month">
                      <div className="bill-rent-month-input">
                        <Controller
                          control={control}
                          name="toMonth"
                          render={({ field }) => (
                            <DatePicker
                              className="monthDatePicker"
                              onChange={(date) => field.onChange(date)}
                              selected={field.value}
                              dateFormat="MM/yyyy"
                              showMonthYearPicker
                              placeholderText="MM/YY"
                              // minDate={new Date(getTodayDate())}
                            />
                          )}
                        />
                      </div>
                      <div className="bill-rent-month-input">
                        <Controller
                          control={control}
                          name="rentForMonth"
                          render={(field) => (
                            <BillingAddInputField
                              {...field}
                              placeholder="Rent For Month"
                              bookingEditInput="bookingEditInput"
                               disabled="disabled"
                              type="number"
                            />
                          )}
                          rules={{ required: true }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="py-2 px-4 billingMainInputBlock">
                    <div className="billingInputLabelBlock">
                      <label className="BillingAddInputLabel">
                        Old Balance
                      </label>
                    </div>
                    <div className="billingInputFieldBlock">
                      <Controller
                        control={control}
                        name="others"
                        render={(field) => (
                          <BillingAddInputField
                            {...field}
                            placeholder="Old Balance"
                            bookingEditInput="bookingEditInput"
                            type="number"
                             disabled="disabled"
                          />
                        )}
                        // rules={{ required: true }}
                      />
                    </div>
                  </div>
                  <div className="py-2 px-4 billingMainInputBlock">
                    <div className="billingInputLabelBlock">
                      <label className="BillingAddInputLabel">Total</label>
                    </div>
                    <div className="billingInputFieldBlock">
                      <Controller
                        control={control}
                        name="total"
                        render={(field) => (
                          <BillingAddInputField
                            {...field}
                            placeholder="Total"
                            bookingEditInput="bookingEditInput"
                            type="number"
                             disabled="disabled"
                          />
                        )}
                      />
                    </div>
                  </div>
                  <div className="py-2 px-4 billingMainInputBlock">
                    <div className="billingInputLabelBlock">
                      <label className="BillingAddInputLabel">
                        Currently Paid Amount
                      </label>
                    </div>
                    <div className="billingInputFieldBlock">
                      <Controller
                        control={control}
                        name="currentlyPaidAmount"
                        render={(field) => (
                          <BillingAddInputField
                            {...field}
                            placeholder="Currently Paid Amount"
                            bookingEditInput="bookingEditInput"
                            type="number"
                          />
                        )}
                        // rules={{ required: true }}
                      />
                    </div>
                  </div>
                  <div className="py-2 px-4 billingMainInputBlock">
                    <div className="billingInputLabelBlock">
                      <label className="BillingAddInputLabel">
                        Remaining Amount
                      </label>
                    </div>
                    <div className="billingInputFieldBlock">
                      <Controller
                        control={control}
                        name="remainingAmount"
                        render={(field) => (
                          <BillingAddInputField
                            {...field}
                            placeholder="Remaining Amount"
                            bookingEditInput="bookingEditInput"
                            type="number"
                          />
                        )}
                        // rules={{ required: true }}
                      />
                    </div>
                  </div>

                  <div className="py-4 px-4  billingSubmitButton">
                    <button type="submit" className="formSubmit">
                      {isLoading ? (
                        <i class="fa fa-spinner fa-spin"></i>
                      ) : (
                        "Submit"
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <PopUpModal isOpen={updatePopup === null}>
        <div className="popup-overlay">
          <div className="confirmation-popup">
            <div className="p-0 m-0 popupBoxBack">
              <div
                style={{ width: "15px", height: "15px", cursor: "pointer" }}
                onClick={() => {
                  setUpdatePopup(null);
                }}
              >
                <img
                  src="/assets/images/close.png"
                  className="popUpBackGround"
                />
              </div>
            </div>
            <div className=" pt-2 d-flex flex-column justify-content-center align-items-center">
              <p className="popupDeleteUser  m-0 p-o">
                Bill has been updated successfully..
              </p>
              <button
                onClick={() => {
                  navigate("/viewBill");
                }}
              >
                OK
              </button>
            </div>
          </div>
        </div>
      </PopUpModal>
    </>
  );
};

export default EditBill;
