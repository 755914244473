import axios from "axios";
import React, { useState, useEffect } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import PopUpModal from "../components/PopUpModal";
import Sidebar from "../components/Sidebar";
import html2pdf from "html2pdf.js";

const ViewBill = () => {
  const queryClient = useQueryClient();
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [addSubTicketPopUp, setAddSubTicketPopUp] = useState(null);
  const [deletePopup, setDeletePopUp] = useState(null);
  const itemsPerPage = 5;
  const [deleteId, setDeleteId] = useState(null);
  const navigate = useNavigate();
  const [filter, setFilter] = useState("");
  const [count, setCount] = useState(0);
  const [paid, setPaid] = useState(0);
  const [pendingamt, setPendingamt] = useState(0);
  let hostelName = localStorage.getItem("hostelName");
  console.log("HOSTEL NAME : ", hostelName);
  const getViewBill = async () => {
    const response = await axios.get(
      `https://api.2kvirtualworld.com/hostelBillingmanagement-0.0.1-SNAPSHOT/newbills/getAllNewBill/${hostelName}`
    );
    return response.data;
  };

  const { data, isLoading, isError } = useQuery("getViewBill", getViewBill);

  const details = data;
  console.log("details", details);

  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    const applyFilterAndSearch = () => {
      if (!details) {
        setFilteredData([]);
        return;
      }

      // Convert searchTerm to lowercase once for consistency
      const lowercasedSearchTerm = searchTerm?.toLowerCase() || "";

      const result = details.filter((item) => {
        // Ensure that the filter criteria is applied
        const matchesFilter = filter ? item.feesStatus === filter : true;

        // Normalize item fields to lowercase for comparison
        const itemRoomNo = (item?.roomNo ?? "").toString().toLowerCase();
        const itemName = (item?.name ?? "").toString().toLowerCase();
        const itemBillNo = (item?.billNo ?? "").toString().toLowerCase();

        // Check if any of the normalized fields match the searchTerm
        const matchesSearch =
          !searchTerm ||
          itemRoomNo.startsWith(lowercasedSearchTerm) ||
          itemName.startsWith(lowercasedSearchTerm) ||
          itemBillNo.startsWith(lowercasedSearchTerm);

        return matchesFilter && matchesSearch;
      });

      setFilteredData(result);
    };

    applyFilterAndSearch();
  }, [details, filter, searchTerm]);

  // Pagination logic
  const totalPages = Math.ceil(filteredData?.length / itemsPerPage);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData?.slice(indexOfFirstItem, indexOfLastItem);

  // Pagination handlers
  const isPrevDisabled = currentPage === 1;
  const isNextDisabled = currentPage === totalPages || totalPages === 0;

  const handlePrevPage = () => {
    if (!isPrevDisabled) setCurrentPage(currentPage - 1);
  };

  const handleNextPage = () => {
    if (!isNextDisabled) setCurrentPage(currentPage + 1);
  };

  const deleteItem = async (id) => {
    await axios.delete(
      `https://api.2kvirtualworld.com/hostelBillingmanagement-0.0.1-SNAPSHOT/newbills/${hostelName}/${id}`
    );
  };

  const mutation = useMutation(deleteItem, {
    onSuccess: () => {
      queryClient.invalidateQueries("getViewBill");

      setDeletePopUp(1); // Show success popup
    },
    onError: (err) => {
      console.log("Error", err);
    },
  });

  const handleDelete = (id) => {
    mutation.mutate(id);
    setAddSubTicketPopUp(null); // Close confirmation popup
  };
  useEffect(() => {
    // Initialize totals
    let totalbill = 0;
    let paidCount = 0;
    let pendingCount = 0;

    // Compute totals
    currentItems?.forEach((item) => {
      totalbill += 1;
      if (item?.remainingAmount === 0) {
        paidCount += 1;
      } else if (item?.remainingAmount !== 0) {
        pendingCount += 1;
      }
    });

    // Update state with computed totals
    setCount(totalbill);
    setPaid(paidCount);
    setPendingamt(pendingCount);
  }, [currentItems]);

  useEffect(() => {
    const datas = details?.map((val, i) => {
      return val?.date;
    });

    setCurrentPage(1);
  }, [searchTerm]);

  return (
    <>
      <section className="viewBillMainSection">
        <div className="container-fluid">
          <div className="row p-0">
            <div className="col-lg-2 p-0 m-0">
              <Sidebar />
            </div>
            <div className="col-lg-10 py-3 px-4 m-0">
              <div className="view-bill-head-block">
                <div className="view-bill-title-block">
                  <img
                    src="/assets/images/back-arrow.png"
                    style={{ width: "40px", height: "40px", cursor: "pointer" }}
                    className="arrow-img-icon"
                    alt="no-image"
                    onClick={() => navigate(-1)}
                  />
                  <h5 className="view-bill-heading pt-2">VIEW BILL</h5>
                </div>
                <div className="view-bill-search-container">
                  <select
                    className="view-bill-status"
                    onChange={(e) => setFilter(e.target.value)}
                    value={filter}
                  >
                    <option value="">All</option>
                    <option value="Paid">Paid</option>
                    <option value="Pending">Pending</option>
                  </select>
                  <input
                    type="search"
                    className="view-bill-search"
                    placeholder="search"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </div>
              </div>

              <div className="status-row-bar">
                <div className="bill-status-bar --first-bar">
                  <p className="bill-status-content">PAID</p>
                  <p className="bill-status-count">{paid}</p>
                </div>
                <div className="bill-status-bar">
                  <p className="bill-status-content">TOTAL</p>
                  <p className="bill-status-count">{count}</p>
                </div>
                <div className="bill-status-bar --last-bar">
                  <p className="bill-status-content">PENDING</p>
                  <p className="bill-status-count">{pendingamt}</p>
                </div>{" "}
              </div>

              <div className="view-bill-main-block">
                <div className="table-responsive table-container">
                  <table className="table">
                    <thead>
                      <tr>
                        <th className="view-bill-head --first-head">NAME</th>
                        <th className="view-bill-head">DOWNLOAD</th>
                        <th className="view-bill-head">ROOM NO</th>
                        <th className="view-bill-head">BILL NO</th>
                        <th className="view-bill-head">MOBILE NUMBER</th>
                        <th className="view-bill-head">EMAILID</th>
                        <th className="view-bill-head">DATE</th>
                        <th className="view-bill-head">RENT FOR MONTH</th>
                        <th className="view-bill-head">EB AMOUNT</th>
                        <th className="view-bill-head">OTHERS</th>
                        <th className="view-bill-head">TOTAL AMOUNT</th>
                        <th className="view-bill-head">AMOUNT PAID</th>
                        <th className="view-bill-head">PENDING AMOUNT</th>
                        <th className="view-bill-head --last-head">EDIT</th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentItems && currentItems.length > 0 ? (
                        currentItems?.map((item, index) => {
                          return (
                            <>
                              <tr key={index}>
                                <td className="view-bill-data">{item?.name}</td>
                                <td className="view-bill-data">
                                  <img
                                    src="/assets/images/download_icon.png"
                                    alt="no-image"
                                    className="--image-click"
                                    onClick={() => {
                                      navigate("/billingInvoice", {
                                        state: item,
                                      });
                                    }}
                                  />
                                </td>
                                <td className="view-bill-data">
                                  {item?.roomNo}
                                </td>
                                <td className="view-bill-data">
                                  {item?.billNo}
                                </td>
                                <td className="view-bill-data">
                                  {item?.mobileNumber}
                                </td>
                                <td className="view-bill-data">
                                  {item?.emailId}
                                </td>
                                <td className="view-bill-data">{item?.date}</td>
                                <td className="view-bill-data">
                                  {item?.rentForMonth}
                                </td>
                                <td className="view-bill-data">
                                  {item?.currentAmount}
                                </td>
                                <td className="view-bill-data">
                                  {item?.others}
                                </td>
                                <td className="view-bill-data">
                                  {item?.total}
                                </td>
                                <td className="view-bill-data">
                                  {item?.currentlypaidAmount}
                                </td>
                                <td className="view-bill-data">
                                  {item?.remainingAmount}
                                </td>
                                <td className="view-bill-data">
                                  <img
                                    src="/assets/images/edit-icon.png"
                                    className="--image-click"
                                    alt="no-image"
                                    onClick={() =>
                                      navigate("/editBill", { state: item })
                                    }
                                  />
                                  <img
                                    src="/assets/images/delete-icon.png"
                                    className="--image-click"
                                    alt="no-image"
                                    onClick={() => {
                                      setAddSubTicketPopUp(true);
                                      setDeleteId(item?.id);
                                    }}
                                  />
                                </td>
                              </tr>
                            </>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan="14">No results found</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>

                <div className="d-flex justify-content-between pt-2 px-1">
                  <button
                    onClick={handlePrevPage}
                    disabled={isPrevDisabled}
                    className="arrow-btn"
                  >
                    &lt;
                  </button>
                  <button
                    onClick={handleNextPage}
                    disabled={isNextDisabled}
                    className="arrow-btn"
                  >
                    &gt;
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <PopUpModal isOpen={addSubTicketPopUp === null}>
        <div className="popup-overlay">
          <div className="confirmation-popup">
            <div className="p-0 m-0 popupBoxBack">
              <div
                style={{ width: "15px", height: "15px", cursor: "pointer" }}
                onClick={() => setAddSubTicketPopUp(null)}
              >
                <img
                  src="/assets/images/close.png"
                  className="popUpBackGround"
                />
              </div>
            </div>
            <div className="pt-2 d-flex flex-column justify-content-center align-items-center">
              <p className="popupDeleteUser m-0 px-1">
                Are you sure you want to delete the user detail?
              </p>
              <div className="d-flex justify-content-center align-items-center gap-2">
                <button
                  onClick={() => {
                    handleDelete(deleteId);
                  }}
                >
                  {isLoading ? <i class="fa fa-spinner fa-spin"></i> : "YES"}
                </button>
                <button onClick={() => setAddSubTicketPopUp(null)}>NO</button>
              </div>
            </div>
          </div>
        </div>
      </PopUpModal>

      <PopUpModal isOpen={deletePopup === null}>
        <div className="popup-overlay">
          <div className="confirmation-popup">
            <div className="p-0 m-0 popupBoxBack">
              <div
                style={{ width: "15px", height: "15px", cursor: "pointer" }}
                onClick={() => setDeletePopUp(null)}
              >
                <img
                  src="/assets/images/close.png"
                  className="popUpBackGround"
                />
              </div>
            </div>
            <div className="pt-2 d-flex flex-column justify-content-center align-items-center">
              <p className="popupDeleteUser m-0 p-0">
                Bill has been deleted successfully.
              </p>
              <button
                onClick={() => {
                  setDeletePopUp(null);
                  window.location.reload();
                }}
              >
                OK
              </button>
            </div>
          </div>
        </div>
      </PopUpModal>
    </>
  );
};

export default ViewBill;
