import React from "react";

const BillingAddInputField = (props) => {
  return (
    <div>
      <input
        type={props&&props.type}
        placeholder={props&&props.placeholder}
        {...props.field}
        className={`${props&&props?.bookingEditInput}
        ${props&&props?.login_input_field}
        ${props&&props?.otherAmount}
        ${props&&props?.newRegisterInput}
        ${props&&props?.newRegisterInputTwo}
        ${props&&props?.loginregisterpass}
        `}
        list={props.list}
        disabled={props&&props?.disabled}
      ></input>
    </div>
  );
};

export default BillingAddInputField;
