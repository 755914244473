import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Sidebar from "../Sidebar";
import { useQuery } from "react-query";
import axios from "axios";

const ViewBeds = () => {
  const { state } = useLocation();
  console.log("STATE", state);
  const navigate = useNavigate();
  let hostelName = localStorage.getItem("hostelName");
  const getViewRegister = async () => {
    return await axios.get(
      `https://api.2kvirtualworld.com/hostelBillingmanagement-0.0.1-SNAPSHOT/newregister/getAllNewRegister/${hostelName}`
    );
  };

  const { data, isLoading, isError } = useQuery(
    "getViewRegister",
    getViewRegister
  );
  let details = data && data?.data;
  console.log("DETAILS", details);
  let filteredDetails;

  if (Array.isArray(details) && state?.roomNo != null) {
    // Filter the details array to include only the objects with the matching roomNo
    filteredDetails = details?.filter((item) => item?.roomNo === state?.roomNo);
    console.log("MAPPED DETAILS", filteredDetails);
  }

  return (
    <>
      <section className="viewBillMainSection">
        <div className="container-fluid">
          <div className="row p-0">
            <div className="col-lg-2 p-0 m-0">
              <Sidebar />
            </div>
            <div className="col-lg-10 py-3 px-4 m-0">
              <div className="view-beds-heading-block">
                <div className="view-beds-head">
                  <img
                    src="/assets/images/back-arrow.png"
                    style={{ width: "40px", height: "40px", cursor: "pointer" }}
                    className="arrow-img-icon"
                    alt="no-image"
                    onClick={() => navigate(-1)}
                  />
                  <h5 className="view-bill-heading pt-2">VIEW BED'S</h5>
                </div>
              </div>
              <div className="view-bed-main-block">
                <h6 className="view-beds-title">
                  ROOM NUMBER : {state?.roomNo}
                </h6>
                <div className="beds-card-block">
                  {filteredDetails?.length == 0 ? (
                    <>
                      <div
                        class="card text-center card-block"
                        onClick={() => {
                          navigate("/newRegister", { state: state });
                        }}
                      >
                        <div class="card-body view-beds-add-block">
                          <img
                            src="/assets/images/addIcon.png"
                            alt="no-image"
                          />
                          <h6 className="card-content-para">ADD USER</h6>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      {filteredDetails &&
                        filteredDetails?.map((e, i) => {
                          return (
                            <>
                              <div class="card text-center  card-block" key={i}>
                                <div class="card-body d-flex justify-content-center align-items-center flex-column">
                                  <h6 className="d-flex gap-3 p-0 m-0">
                                    <span class="card-title card-content-para">
                                      {e?.bedNo} - {e?.name} - {e?.status}
                                    </span>
                                  </h6>
                                </div>
                              </div>
                            </>
                          );
                        })}
                      {(state && state?.status == "available") ||
                      (state && state?.status == "Available") ? (
                        <>
                          <div
                            class="card text-center card-block"
                            onClick={() => {
                              navigate("/newRegister", {
                                state: state,
                              });
                            }}
                          >
                            <div class="card-body d-flex align-items-center justify-content-center">
                              <img
                                src="/assets/images/addIcon.png"
                                alt="no-image"
                              />
                              <h6 className="card-content-para">ADD USER</h6>
                            </div>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ViewBeds;
