import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useLocation } from "react-router-dom";
import BillingAddInputField from "../components/BillingAddInputField";
import PopUpModal from "../components/PopUpModal";

import { useMutation, useQuery } from "react-query";
import axios from "axios";
import moment from "moment";
import Sidebar from "../components/Sidebar";

const RentBill = () => {
  const { state } = useLocation();
  console.log("STATE", state);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    watch,
  } = useForm();
  const [addSubTicketPopUp, setAddSubTicketPopUp] = useState(null);
  const [sharePopUp, setSharePopUp] = useState(null);
  const [details, setDetails] = useState();
  const [searchTerm, setSearchTerm] = useState("");
  let hostelName = localStorage.getItem("hostelName");
  console.log("HOSTEL NAME : ", hostelName);
  // Function to get today's date in YYYY-MM-DD format
  const getTodayDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  const getViewRegister = async () => {
    return await axios.get(
      `https://api.2kvirtualworld.com/hostelBillingmanagement-0.0.1-SNAPSHOT/newregister/getAllNewRegister/${hostelName}`
    );
  };

  const { mutate, isLoading, isError, isSuccess } = useMutation(
    (postData) =>
      axios.post(
        `https://api.2kvirtualworld.com/hostelBillingmanagement-0.0.1-SNAPSHOT/newbills/${hostelName}`,
        postData
      ),
    {
      onSuccess: () => {
        console.log("data added successfully...");
        setAddSubTicketPopUp(1);
      },
      onError: (error) => {
        // Handle error
        console.error("Mutation failed:", error);
      },
    }
  );
  const { data } = useQuery("getViewRegister", getViewRegister);
  const registerDetails = data && data?.data;
  const {
    currentAmount,
    rentForMonth,
    others,
    currentlyPaidAmount,
    powerUsedFrom,
    powerUsedTo,
  } = watch();
  const currentamt = Number(powerUsedTo) - Number(powerUsedFrom);
  const currentamounts = 6 * currentamt;

  const totalAmount = `${
    Number(currentAmount) + Number(rentForMonth) + Number(others)
  }`;
  const remainingbalance = `${
    Number(totalAmount) - Number(currentlyPaidAmount)
  }`;

  useEffect(() => {
    setValue("currentAmount", currentamounts);
    setValue("total", totalAmount);
    setValue("remainingAmount", remainingbalance);
  }, [totalAmount, remainingbalance,currentamounts]);
  const onSubmit = (data) => {
    let formData = {
      name: data?.name,
      date: moment(data?.date).format("YYYY-MM-DD"),
      mobileNumber: data?.mobileNumber,
      emailId: data?.email,
      roomNo: data?.roomNo,
      currentAmount: data?.currentAmount,
      others: data?.others,
      rentForMonth: data?.rentForMonth,
      role: data?.role,
      total: data?.total,
      billNo: data?.billNo,
      powerUsedTo: data?.powerUsedTo,
      powerUsedFrom: data?.powerUsedFrom,
      currentlypaidAmount: data?.currentlyPaidAmount,
      remainingAmount: data?.remainingAmount,
      // selecthostel:
    };
    localStorage.setItem("role", data?.role);
    setDetails(formData);
    console.log("formData", formData);
    if (formData) {
      mutate(formData);
    }
    // navigate('/billingInvoice',{state:formData})
  };
  const users = registerDetails?.filter(
    (item) => Number(item?.mobileNo) === Number(searchTerm)
  );
  const usersdetails = users?.[0] || {};
  console.log("userdetails", usersdetails);
  useEffect(() => {
    if (searchTerm && searchTerm) {
      setValue("name", usersdetails?.name || "");
      setValue("roomNo", usersdetails?.roomNo || "");
      setValue("mobileNumber", usersdetails?.mobileNo || "");
      setValue("email", usersdetails?.emailId || "");
      setValue("rentForMonth", usersdetails?.rentForMonth || "");
    }
  }, [searchTerm, usersdetails, setValue]);
  // const { name, roomNo, mobileNumber, email } = watch();
  useEffect(() => {
    setValue("name", state?.name || "");
    setValue("roomNo", state?.roomNo || "");
    setValue("mobileNumber", state?.mobileNo || "");
    setValue("email", state?.emailId || "");
    setValue("rentForMonth", state?.rentForMonth);
  }, []);
  return (
    <>
      <section className="rentBillMainSection">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-2 p-0 m-0">
              <Sidebar />
            </div>
            <div className="col-lg-10 py-1 m-0 px-4">
              <div className="new-bill-head-block">
                <div className="new-bill-heading-block">
                  <img
                    src="/assets/images/back-arrow.png"
                    className="arrow-img-icon"
                    alt="no-image"
                    onClick={() => {
                      navigate(-1);
                    }}
                  />
                  <h6 className="rentBillHeading pt-2">NEW BILL</h6>
                </div>
                <div className="new-bill-search-block">
                  <input
                    type="search"
                    className="table-search --bill-search"
                    placeholder="Mobile Number"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                  <Controller
                    name="role"
                    control={control}
                    defaultValue=""
                    rules={{ required: true }} // Example: Add validation rules
                    render={({ field }) => (
                      <select {...field} className="selectDropDown">
                        <option value="">SELECT A HOSTEL</option>
                        <option value="SM Homes">SM Homes</option>
                        <option value="Elite">Elite Stays</option>
                      </select>
                    )}
                  />
                </div>
              </div>

              <div className="py-2">
                <form
                  onSubmit={handleSubmit(onSubmit)}
                  className="rentalFormBlock"
                >
                  <div className=" py-2 px-4 billingMainInputBlock">
                    <div className="billingInputLabelBlock">
                      <label className="BillingAddInputLabel">Date</label>
                    </div>
                    <div className="billingInputFieldBlock">
                      <Controller
                        control={control}
                        name="date"
                        render={({ field }) => (
                          <DatePicker
                            className="bookingDateEditInput"
                            onChange={(date) => field.onChange(date)}
                            selected={field.value}
                            // dateFormat="YYYY/MM/DD"
                            // dateFormat='Pp/'
                            placeholderText="DD/MM/YY"
                            minDate={new Date(getTodayDate())} // Disable previous days
                          />
                        )}
                        rules={{ required: true }}
                      />
                    </div>
                  </div>
                  <div className="py-2 px-4 billingMainInputBlock">
                    <div className="billingInputLabelBlock">
                      <label className="BillingAddInputLabel">Bill No</label>
                    </div>
                    <div className="billingInputFieldBlock">
                      <Controller
                        control={control}
                        name="billNo"
                        render={(field) => (
                          <BillingAddInputField
                            {...field}
                            placeholder="Bill Number"
                            type="number"
                            bookingEditInput="bookingEditInput"
                          />
                        )}
                        rules={{ required: true }}
                      />
                    </div>
                  </div>
                  <div className="py-2 px-4 billingMainInputBlock">
                    <div className="billingInputLabelBlock">
                      <label className="BillingAddInputLabel">Name</label>
                    </div>
                    <div className="billingInputFieldBlock">
                      <Controller
                        control={control}
                        name="name"
                        render={(field) => (
                          <BillingAddInputField
                            {...field}
                            placeholder="Customer Name"
                            type="text"
                            bookingEditInput="bookingEditInput"
                          />
                        )}
                        rules={{ required: true }}
                      />
                    </div>
                  </div>
                  <div className="py-2 px-4 billingMainInputBlock">
                    <div className="billingInputLabelBlock">
                      <label className="BillingAddInputLabel">
                        Mobile Number
                      </label>
                    </div>
                    <div className="billingInputFieldBlock">
                      <Controller
                        control={control}
                        name="mobileNumber"
                        render={(field) => (
                          <BillingAddInputField
                            {...field}
                            placeholder="Mobile Number"
                            type="number"
                            bookingEditInput="bookingEditInput"
                          />
                        )}
                        rules={{ required: true }}
                      />
                    </div>
                  </div>
                  <div className="py-2 px-4 billingMainInputBlock">
                    <div className="billingInputLabelBlock">
                      <label className="BillingAddInputLabel">Email Id</label>
                    </div>
                    <div className="billingInputFieldBlock">
                      <Controller
                        control={control}
                        name="email"
                        render={(field) => (
                          <BillingAddInputField
                            {...field}
                            placeholder="Email Id"
                            type="emailid"
                            bookingEditInput="bookingEditInput"
                          />
                        )}
                        rules={{ required: true }}
                      />
                    </div>
                  </div>
                  <div className="py-2 px-4 billingMainInputBlock">
                    <div className="billingInputLabelBlock">
                      <label className="BillingAddInputLabel">Room No</label>
                    </div>
                    <div className="billingInputFieldBlock">
                      <Controller
                        control={control}
                        name="roomNo"
                        render={(field) => (
                          <BillingAddInputField
                            {...field}
                            placeholder="Room No"
                            bookingEditInput="bookingEditInput"
                            type="number"
                          />
                        )}
                        rules={{ required: true }}
                      />
                    </div>
                  </div>
                  <div className="py-2 px-4 billingInputBlock">
                    <div className="billingInputLabelBlock">
                      <label className="BillingAddInputLabel">
                        Power Used From
                      </label>
                    </div>
                    <div className="new-bill-units-block">
                      <div className="new-bill-from-to-month">
                        <Controller
                          name="fromMonth"
                          control={control}
                          render={({ field }) => (
                            <DatePicker
                              className="billingDateField"
                              selected={
                                field.value ? new Date(field.value) : null
                              }
                              onChange={(date) =>
                                field.onChange(
                                  moment(date).format("YYYY-MM-DD")
                                )
                              }
                              placeholderText="Select from date"
                            />
                          )}
                        />
                      </div>
                      <div className="new-bill-from-to-label">
                        <label className="BillingAddInputLabel">To</label>
                      </div>
                      <div className="new-bill-from-to-month">
                        <Controller
                          name="toMonth"
                          control={control}
                          render={({ field }) => (
                            <DatePicker
                              className="billingDateField"
                              selected={
                                field.value ? new Date(field.value) : null
                              }
                              onChange={(date) =>
                                field.onChange(
                                  moment(date).format("YYYY-MM-DD")
                                )
                              }
                              placeholderText="Select to date"
                            />
                          )}
                        />
                      </div>
                      <div className="new-billpowerusedunit">
                        <div className="fromUnitsBlock">
                          <Controller
                            control={control}
                            name="powerUsedFrom"
                            render={(field) => (
                              <BillingAddInputField
                                {...field}
                                placeholder="from Units"
                                bookingEditInput="bookingEditInput"
                                type="number"
                              />
                            )}
                            rules={{ required: true }}
                          />
                        </div>
                        <div className="toUnitsBlock">
                          <Controller
                            control={control}
                            name="powerUsedTo"
                            render={(field) => (
                              <BillingAddInputField
                                {...field}
                                placeholder="to Units"
                                bookingEditInput="bookingEditInput"
                                type="number"
                              />
                            )}
                            rules={{ required: true }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="py-2 px-4  power-amount">
                    <div className="power-amount-input-block">
                      <Controller
                        control={control}
                        name="currentAmount"
                        render={(field) => (
                          <BillingAddInputField
                            {...field}
                            placeholder="currentAmount"
                            bookingEditInput="bookingEditInput"
                            type="number"
                          />
                        )}
                        rules={{ required: true }}
                      />
                    </div>
                  </div>
                  <div className="py-2 px-4 billingInputBlock">
                    <div className="billingInputLabelBlock">
                      <label className="BillingAddInputLabel">
                        Rent For Month
                      </label>
                    </div>
                    <div className="bill-rent-month">
                      <div className="bill-rent-month-input">
                        <Controller
                          control={control}
                          name="toMonth"
                          render={({ field }) => (
                            <DatePicker
                              className="monthDatePicker"
                              onChange={(date) => field.onChange(date)}
                              selected={field.value}
                              dateFormat="MM/yyyy"
                              showMonthYearPicker
                              placeholderText="MM/YY"
                              // minDate={new Date(getTodayDate())}
                            />
                          )}
                        />
                      </div>
                      <div className="bill-rent-month-input">
                        <Controller
                          control={control}
                          name="rentForMonth"
                          render={(field) => (
                            <BillingAddInputField
                              {...field}
                              placeholder="Rent For Month"
                              bookingEditInput="bookingEditInput"
                              type="number"
                            />
                          )}
                          rules={{ required: true }}
                        />
                      </div>
                    </div>
                  </div>
                  {/* OLD BALANCE START */}
                  <div className="py-2 px-4 billingMainInputBlock">
                    <div className="billingInputLabelBlock">
                      <label className="BillingAddInputLabel">Others</label>
                    </div>
                    <div className="billingInputFieldBlock">
                      <Controller
                        control={control}
                        name="others"
                        render={(field) => (
                          <BillingAddInputField
                            {...field}
                            placeholder="Others"
                            bookingEditInput="bookingEditInput"
                            type="number"
                          />
                        )}
                        // rules={{ required: true }}
                      />
                    </div>
                  </div>

                  {/* OLD BALANCE END */}
                  <div className="py-2 px-4 billingMainInputBlock">
                    <div className="billingInputLabelBlock">
                      <label className="BillingAddInputLabel">
                        Total Amount
                      </label>
                    </div>
                    <div className="billingInputFieldBlock">
                      <Controller
                        control={control}
                        name="total"
                        render={(field) => (
                          <BillingAddInputField
                            {...field}
                            placeholder="Total"
                            bookingEditInput="bookingEditInput"
                            type="number"
                          />
                        )}
                        // rules={{ required: true }}
                      />
                    </div>
                  </div>
                  <div className="py-2 px-4 billingMainInputBlock">
                    <div className="billingInputLabelBlock">
                      <label className="BillingAddInputLabel">
                        Currently Paid Amount
                      </label>
                    </div>
                    <div className="billingInputFieldBlock">
                      <Controller
                        control={control}
                        name="currentlyPaidAmount"
                        render={(field) => (
                          <BillingAddInputField
                            {...field}
                            placeholder="Currently Paid Amount"
                            bookingEditInput="bookingEditInput"
                            type="number"
                          />
                        )}
                        // rules={{ required: true }}
                      />
                    </div>
                  </div>
                  <div className="py-2 px-4 billingMainInputBlock">
                    <div className="billingInputLabelBlock">
                      <label className="BillingAddInputLabel">
                        Remaining Amount
                      </label>
                    </div>
                    <div className="billingInputFieldBlock">
                      <Controller
                        control={control}
                        name="remainingAmount"
                        render={(field) => (
                          <BillingAddInputField
                            {...field}
                            placeholder="Remaining Amount"
                            bookingEditInput="bookingEditInput"
                            type="number"
                          />
                        )}
                        // rules={{ required: true }}
                      />
                    </div>
                  </div>

                  <div className="py-4 px-4  billingSubmitButton">
                    <button type="submit" className="formSubmit">
                      {isLoading ? (
                        <i class="fa fa-spinner fa-spin"></i>
                      ) : (
                        "Submit"
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <PopUpModal isOpen={addSubTicketPopUp === null}>
        <div className="p-0 m-0 popupBoxBack">
          <div
            style={{ width: "15px", height: "15px", cursor: "pointer" }}
            onClick={() => {
              setAddSubTicketPopUp(null);
            }}
          >
            <img src="/assets/images/close.png" className="popUpBackGround" />
          </div>
        </div>
        <div className="d-flex flex-column justify-content-center align-items-center">
          <span className="popUpContent py-2">Thank you!</span>
          <span className="popUpContentPara ">Bill added successfully..</span>
          <button
            onClick={() => {
              navigate("/viewBill");
            }}
          >
            OK
          </button>
        </div>
      </PopUpModal>
    </>
  );
};

export default RentBill;
