import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import BillingAddInputField from "../components/BillingAddInputField";
import { useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import axios from "axios";

const Login = () => {
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const [error, setError] = useState();
  const [type, setType] = useState("password");
  const [isShow, setIsShow] = useState(true);

  const { mutate, isError, isLoading, status } = useMutation(
    (postdata) =>
      axios.post(
        `https://api.2kvirtualworld.com/hostelBillingmanagement-0.0.1-SNAPSHOT/registers/login`,
        postdata
      ),
    {
      onSuccess: (obj) => {
        console.log("register", obj && obj?.data && obj?.data?.register);
        // console.log("Login",obj&&obj?.data&&obj?.data?.message);
        const hostelName = obj && obj?.data && obj?.data?.register?.shopName;
        localStorage.setItem("hostelName", hostelName);

        const userObject = {
          accountName: obj && obj?.data && obj?.data?.register?.accountName,
          accountNumber: obj && obj?.data && obj?.data?.register?.accountNumber,
          accountType: obj && obj?.data && obj?.data?.register?.accountType,
          bankName: obj && obj?.data && obj?.data?.register?.bankName,
          branch: obj && obj?.data && obj?.data?.register?.branch,
          ifsccode: obj && obj?.data && obj?.data?.register?.ifscCode,
          registerNumber:
            obj && obj?.data && obj?.data?.register?.registerNumber,
          shopName: obj && obj?.data && obj?.data?.register?.shopName,
          companyAddress:
            obj && obj?.data && obj?.data?.register?.companyAddress,
          companyLocation:
            obj && obj?.data && obj?.data?.register?.companyLocation,
          companyPincode:
            obj && obj?.data && obj?.data?.register?.companyPincode,
          role: obj && obj?.data && obj?.data?.register?.role,
          customerName: obj && obj?.data && obj?.data?.register?.customerName,
          emailId: obj && obj?.data && obj?.data?.register?.emailId,
          customerPhno: obj && obj?.data && obj?.data?.register?.customerPhno,
          ifscCode: obj && obj?.data && obj?.data?.register?.ifscCode,
          id: obj && obj?.data && obj?.data?.register?.id,
        };
        const userString = JSON.stringify(userObject);
        localStorage.setItem("billInfo", userString);

        localStorage.setItem("login", obj && obj?.data && obj?.data?.message);

        window.location.reload();
        navigate("/");
      },
      onError: (error) => {
        setError(error);
        console.error("Mutation failed:", error);
      },
    }
  );

  const onSubmit = (data) => {
    console.log("Data", data);
    mutate(data);
  };
  return (
    <>
      <section className="mainSection">
        <div className="container">
          <div className="row mainBlock p-0 m-0">
            <div className="col-lg-12 login-head">
              2k Inventory - Hostel Billing
            </div>

            <div className="col-lg-6 img_blocks justify-content-center align-items-center">
              <div className="loginImageBlock">
                <img
                  src="/assets/images/loginbackground.webp"
                  alt="no-image"
                  className="image_icon"
                />
              </div>
            </div>
            <div className="col-lg-6  d-flex justify-content-center align-items-center p-0 m-0">
              <div className="loginBlock">
                <h6 className="loginHeading">Login</h6>
                <h6 className="loginSubHeading">Welcome Back</h6>
                <form
                  className="loginFormBlock"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <div className="login_input_container">
                    <div className="image_block">
                      <img
                        src="/assets/images/email.png"
                        alt="no-image"
                        className="image_icon"
                      />
                    </div>
                    <span style={{ fontWeight: "bolder", fontSize: "17px" }}>
                      |
                    </span>
                    <div className="billing-login-input-block">
                      <Controller
                        control={control}
                        name="username"
                        render={(field) => (
                          <BillingAddInputField
                            {...field}
                            placeholder="Email address"
                            type="email"
                            login_input_field="login_input_field"
                          />
                        )}
                        rules={{ required: true }}
                      />
                    </div>
                  </div>
                  <div className="login_input_password_container p-0 m-0">
                    <div className="login-password-input-container">
                      <div className="image_block">
                        <img
                          src="/assets/images/password.png"
                          alt="no-image"
                          className="image_icon"
                        />
                      </div>
                      <span style={{ fontWeight: "bolder", fontSize: "17px" }}>
                        |
                      </span>
                      <div className="billing-login-input-block">
                        <Controller
                          control={control}
                          name="password"
                          render={(field) => (
                            <BillingAddInputField
                              {...field}
                              placeholder="Password"
                              type={`${!isShow ? "text" : "password"}`}
                              login_input_field="login_input_field"
                            />
                          )}
                          rules={{ required: true }}
                        />
                      </div>
                    </div>
                    <div
                      className="passwordBlock"
                      onClick={() => {
                        setIsShow(!isShow);
                      }}
                    >
                      {!isShow ? (
                        <img
                          src="/assets/images/visibility_on_white.png"
                          className="image_icon"
                          alt="no-image"
                        />
                      ) : (
                        <img
                          src="/assets/images/visibility_off_white.png"
                          className="image_icon"
                          alt="no-image"
                        />
                      )}
                    </div>
                  </div>
                  <div>
                    {isError && (
                      <p
                        style={{ color: "red", fontWeight: "500" }}
                        className="p-0 m-0"
                      >
                        {error &&
                          error?.response &&
                          error?.response?.data &&
                          error?.response?.data?.message}
                      </p>
                    )}
                  </div>
                  <div className="loginBtnBlock">
                    <button type="submit" className="loginBtn">
                      {isLoading ? (
                        <i className="fa fa-spinner fa-spin"></i>
                      ) : (
                        "Login"
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Login;
