import React from "react";


import Editroom from "../../components/editroomcomp/Editroom"
import Sidebar from "../../components/Sidebar";
const EditRoomPage = () => {
  return (
    <>
      <section className="viewBillMainSection">
        <div className="container-fluid">
          <div className="row p-0">
            <div className="col-lg-2 p-0 m-0">
              <Sidebar />
            </div>
            <div className="col-lg-10 py-3 px-4 m-0">
            <Editroom/>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default EditRoomPage;
